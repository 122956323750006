import { useTheme } from '@mui/material/styles';
import { HoverIconButton } from '@talentmesh/core';
import { StarFilledIcon, StarOutlinedIcon, StarRemoveIcon } from '@talentmesh/icons';
import React from 'react';
import { CellProps } from '.';
import UIStrings from '../../../../../../Utils/UIStrings';
import { useCandidatesOverviewContext } from '../../CandidatesOverviewContext/CandidatesOverviewContext';

function FavoriteCell({ candidate: { candidateId, isFavorite } }: CellProps): JSX.Element {
    const theme = useTheme();
    const { saveFavoriteAsync } = useCandidatesOverviewContext();

    const handleFavoriteOnClick = () => {
        saveFavoriteAsync(candidateId, !isFavorite);
    };

    return (
        <HoverIconButton
            size="large"
            aria-label={UIStrings.Favorite}
            onClick={handleFavoriteOnClick}
            sx={{ color: theme.palette.primary.dark, padding: 0, mx: 'auto' }}
            icon={isFavorite ? <StarFilledIcon /> : <StarOutlinedIcon />}
            hoverIcon={isFavorite ? <StarRemoveIcon /> : <StarFilledIcon />}
        />
    );
}

export default FavoriteCell;
