import React, { useMemo } from 'react';
import { ScoreDTO } from '../../../../../../../Models/CandidateOverview';
import { TalentScore } from '../../../../../../../Models/TalentScore';
import toDateString from '../../../../../../../Utils/DateHelper';
import UIStrings from '../../../../../../../Utils/UIStrings';
import { ApplicantCell, FavoriteCell, HiringActionCell, MoreActionsCell, ScoreCell, TextCell } from '../../cells';
import { CandidateCol, CellParams } from '.';
import SalaryExpectation from '../../../../../../../Models/SalaryExpectation';
import { formatSalaryExpectation } from '../../../../../../Assessments/Modify/Steps/RequestInformation/RequestInformationStepUtils';

/** Put columns that's always included by default here */
const useDefaultColumns = (includesExperienceCriteria: boolean) => {
    return useMemo(() => {
        const columns: CandidateCol[] = [
            {
                accessorKey: 'isFavorite',
                header: UIStrings.Favorite,
                Cell: ({ row }: CellParams) => <FavoriteCell candidate={row.original} />,
                size: 96,
                grow: false,
                enableResizing: false,
                enableSorting: false,
            },
            {
                id: 'applicant',
                header: UIStrings.Applicant,
                accessorFn: (row) => `${row.candidateFirstName} ${row.candidateLastName}`,
                Cell: ({ row, renderedCellValue }: CellParams) => (
                    <ApplicantCell id={row.original.candidateId}>{renderedCellValue}</ApplicantCell>
                ),
                size: 124,
                enableSorting: false,
            },
            {
                id: 'applicantAction',
                header: 'Action',
                Cell: ({ row }: CellParams) => <MoreActionsCell candidate={row.original} />,
                size: 40,
                grow: false,
                enableResizing: false,
                enableSorting: false,
            },
            {
                accessorKey: 'phoneNumber',
                header: UIStrings.PhoneNumber,
                size: 137,
                enableSorting: false,
                Cell: ({ renderedCellValue }: CellParams) => <TextCell value={renderedCellValue} />,
            },
            {
                accessorKey: 'candidateEmail',
                header: UIStrings.Email,
                size: 161,
                enableSorting: false,
                Cell: ({ renderedCellValue }: CellParams) => <TextCell value={renderedCellValue} />,
            },
            {
                accessorKey: 'locationDetails.description',
                accessorFn: ({ locationDetails }) => locationDetails?.description,
                header: UIStrings.CityAndCountry,
                enableSorting: false,
                Cell: ({ renderedCellValue }: CellParams) => <TextCell value={renderedCellValue} />,
                size: 161,
            },
            {
                accessorKey: 'experience.title',
                accessorFn: ({ experience }) => experience?.title,
                header: UIStrings.LatestExperience,
                enableSorting: false,
                Cell: ({ renderedCellValue }: CellParams) => <TextCell value={renderedCellValue} />,
                size: 194,
            },
            {
                accessorKey: 'noticePeriod',
                header: UIStrings.NoticePeriod,
                size: 120,
                enableSorting: false,
                Cell: ({ renderedCellValue }: CellParams) => <TextCell value={renderedCellValue} />,
            },
            {
                accessorKey: 'validWorkPermit',
                header: UIStrings.WorkPermit,
                size: 115,
                enableSorting: false,
                Cell: ({ renderedCellValue }: CellParams) => <TextCell value={renderedCellValue} />,
            },
            {
                id: 'willingToRelocate',
                header: UIStrings.WillingToRelocate,
                accessorFn: ({ willingToRelocate }) => {
                    return willingToRelocate ? UIStrings.Yes : UIStrings.No;
                },
                size: 100,
                enableSorting: false,
                Cell: ({ renderedCellValue }: CellParams) => <TextCell value={renderedCellValue} />,
            },
            {
                id: 'salaryExpectation',
                accessorFn: ({ salaryExpectation }) => {
                    return salaryExpectation
                        ? formatSalaryExpectation(JSON.parse(salaryExpectation) as SalaryExpectation)
                        : '';
                },
                header: UIStrings.ExpectedYearlySalary,
                size: 141,
                enableSorting: false,
                Cell: ({ renderedCellValue }: CellParams) => <TextCell value={renderedCellValue} />,
            },
            {
                id: 'education',
                header: UIStrings.HighestLevelOfEducation,
                accessorFn: ({ education }) => {
                    if (!education) {
                        return null;
                    }

                    const { educationLevel } = education;
                    if (educationLevel.level === 'UserDefined') {
                        return educationLevel.userDefined;
                    } else {
                        return UIStrings[educationLevel.level!];
                    }
                },
                enableSorting: false,
                Cell: ({ renderedCellValue }: CellParams) => <TextCell value={renderedCellValue} />,
                size: 178,
            },
            {
                id: 'applicantStatus',
                header: UIStrings.ApplicantStatus,
                accessorFn: ({ applicantStatus }) => UIStrings[applicantStatus],
                enableSorting: false,
                Cell: ({ renderedCellValue }: CellParams) => <TextCell value={renderedCellValue} />,
                size: 120,
            },
            {
                id: 'invitedAt',
                header: UIStrings.InvitedDate,
                accessorFn: ({ invitedAt }) => (invitedAt ? toDateString(new Date(invitedAt)) : undefined),
                enableSorting: false,
                Cell: ({ renderedCellValue }: CellParams) => <TextCell value={renderedCellValue} />,
                size: 106,
            },
            {
                id: 'appliedAt',
                header: UIStrings.AppliedDate,
                accessorFn: ({ appliedAt }) => (appliedAt ? toDateString(new Date(appliedAt)) : undefined),
                Cell: ({ renderedCellValue }: CellParams) => <TextCell value={renderedCellValue} />,
                size: 106,
            },
            {
                id: 'startedAt',
                header: UIStrings.AssessmentStartedDate,
                accessorFn: ({ startedAt }) => (startedAt ? toDateString(new Date(startedAt)) : undefined),
                Cell: ({ renderedCellValue }: CellParams) => <TextCell value={renderedCellValue} />,
                size: 106,
            },
            {
                id: 'completedAt',
                header: UIStrings.AssessmentCompletedDate,
                accessorFn: ({ completedAt }) => (completedAt ? toDateString(new Date(completedAt)) : undefined),
                Cell: ({ renderedCellValue }: CellParams) => <TextCell value={renderedCellValue} />,
                size: 106,
            },
            {
                id: 'invitedForInterviewAt',
                header: UIStrings.InvitedForInterviewDate,
                accessorFn: ({ invitedForInterviewAt }) =>
                    invitedForInterviewAt ? toDateString(new Date(invitedForInterviewAt)) : undefined,
                Cell: ({ renderedCellValue }: CellParams) => <TextCell value={renderedCellValue} />,
                size: 106,
            },
            {
                id: 'interviewingAt',
                header: UIStrings.InterviewDate,
                accessorFn: ({ interviewingAt }) =>
                    interviewingAt ? toDateString(new Date(interviewingAt)) : undefined,
                Cell: ({ renderedCellValue }: CellParams) => <TextCell value={renderedCellValue} />,
                size: 106,
            },
            {
                id: 'hiredAt',
                header: UIStrings.HiredDate,
                accessorFn: ({ hiredAt }) => (hiredAt ? toDateString(new Date(hiredAt)) : undefined),
                enableSorting: false,
                Cell: ({ renderedCellValue }: CellParams) => <TextCell value={renderedCellValue} />,
                size: 106,
            },
            {
                id: 'rejectedAt',
                header: UIStrings.RejectedDate,
                accessorFn: ({ rejectedAt }) => (rejectedAt ? toDateString(new Date(rejectedAt)) : undefined),
                enableSorting: false,
                Cell: ({ renderedCellValue }: CellParams) => <TextCell value={renderedCellValue} />,
                size: 106,
            },
            {
                id: 'hiringAction',
                header: UIStrings.HiringAction,
                Cell: ({ row, column }: CellParams) =>
                    row.original.isInternal ? (
                        <TextCell value={UIStrings.InternalBenchmarkLowCase} />
                    ) : (
                        <HiringActionCell candidate={row.original} column={column} />
                    ),
                size: 202,
            },
            {
                accessorKey: 'talentScore',
                header: UIStrings.TalentScore,
                Cell: ({ cell }: CellParams) => <ScoreCell scoring={cell.getValue<TalentScore>()} />,
                size: 120,
            },
        ];

        if (includesExperienceCriteria) {
            columns.push({
                accessorKey: 'experienceCriteriaScore',
                header: UIStrings.Experience,
                Cell: ({ cell }: CellParams) => <ScoreCell scoring={cell.getValue<ScoreDTO>()} />,
                size: 120,
            });
        }

        return columns;
    }, [includesExperienceCriteria]);
};

export default useDefaultColumns;
