import { createContext, useContext } from 'react';
import { AccountSettings, EmailTemplateType, Notifications } from '../Models/Configuration';

export interface AccountSettingsContextData {
    accountSettings: AccountSettings;
    saveEmailTemplateAsync: (
        type: EmailTemplateType,
        title: string,
        bodyHtml: string,
        regards: string,
        templateHtml: string,
    ) => Promise<void>;

    patchNotificationsAsync: (property: keyof Notifications, value: boolean) => Promise<void>;

    openPreview: boolean;
    setOpenPreview: (value: boolean) => void;

    getEmailTemplateHtml: (
        title: string,
        thankYouMessage: string,
        bodyHtml: string,
        regards: string,
        candidateName: string,
        recruiterName: string,
        companyName: string,
        recruitmentName?: string,
        includeRecruiterName?: boolean,
    ) => string;
}

export const AccountSettingsContext = createContext<AccountSettingsContextData | undefined>(undefined);

export function useAccountSettingsContext() {
    const context = useContext(AccountSettingsContext);
    if (!context) {
        throw new Error('useAccountSettingsContext must be used within the AccountSettingsContext.Provider');
    }
    return context;
}
