import React from 'react';
import BaseFilter from './BaseFilter';
import { useCandidatesOverviewContext } from '../../Contexts/CandidatesOverviewContext/CandidatesOverviewContext';
import UIStrings from '../../../../../Utils/UIStrings';
import HiringStatusFilterPanel from './HiringStatusFilterPanel';

function HiringStatusFilterButton(): JSX.Element {
    const { hiringStatusFilter } = useCandidatesOverviewContext();

    return (
        <BaseFilter label={UIStrings.HiringStage} activeFilterCount={hiringStatusFilter.length}>
            <HiringStatusFilterPanel />
        </BaseFilter>
    );
}

export default HiringStatusFilterButton;
