import { useTheme } from '@mui/material';
import {
    Box,
    MenuButton,
    MenuItemWithValueProps,
    PaginationNav,
    PaginationNavProps,
    Typography,
} from '@talentmesh/core';
import { Check } from '@talentmesh/icons';
import React, { useEffect, useMemo, useState } from 'react';
import UIStrings from '../../../../../Utils/UIStrings';
import { useCandidatesOverviewContext } from '../../Contexts/CandidatesOverviewContext/CandidatesOverviewContext';

const paginationSizeOptions = [25, 50, 100, 150, 200];

const TableNav = () => {
    const theme = useTheme();
    const {
        pageNumber: zeroBasedPageNumbering,
        pageSize,
        totalCandidatesCount,
        handlePaginationModelChange,
    } = useCandidatesOverviewContext();
    const [pageNumber, setPageNumber] = useState(0);
    const offset = useMemo(() => (pageNumber - 1) * pageSize, [pageNumber, pageSize]);
    const currentSize = useMemo(
        () => (totalCandidatesCount < pageSize + offset ? totalCandidatesCount : pageSize + offset),
        [totalCandidatesCount, pageSize, offset],
    );
    const pageSizeMenuItems = useMemo(() => {
        return paginationSizeOptions.map<MenuItemWithValueProps>((sizeOption) => ({
            menuItemLabel: sizeOption.toString(),
            selected: pageSize === sizeOption,
            // empty div so there's inset for non selected option
            icon: pageSize === sizeOption ? <Check /> : <div />,
            onClick: () => {
                handlePaginationModelChange({ page: 0, pageSize: sizeOption });
            },
        }));
    }, [pageSize]);

    const updatePageNumber = (page: number) => {
        setPageNumber(page);
        handlePaginationModelChange({ page: page - 1, pageSize });
    };

    useEffect(() => {
        // sync with context (e.g. tab change)
        setPageNumber(zeroBasedPageNumbering + 1);
    }, [zeroBasedPageNumbering, setPageNumber]);

    useEffect(() => {
        // mui dataGrid automatically reset/go back one page if current page number exceeds total data count
        // this behavior is replicated here
        if (totalCandidatesCount && offset + 1 > totalCandidatesCount) {
            const page = (totalCandidatesCount - pageSize) / pageSize + 1;
            updatePageNumber(page);
        }
    }, [offset, totalCandidatesCount, pageSize, setPageNumber]);

    const handlePaginationChange: PaginationNavProps['onChange'] = (_, page) => {
        updatePageNumber(page);
    };

    return (
        <Box
            display="flex"
            flexDirection="row"
            my={theme.spacing(2.5)}
            sx={{ placeContent: 'center', placeItems: 'center', gap: theme.spacing(2.5) }}
        >
            <PaginationNav
                page={pageNumber}
                count={Math.ceil(totalCandidatesCount / pageSize)}
                onChange={handlePaginationChange}
                boundaryCount={0}
            />
            <Typography variant="caption">
                {UIStrings.PaginationSizeInfo(
                    totalCandidatesCount > 0 ? offset + 1 : 0,
                    currentSize,
                    totalCandidatesCount,
                )}
            </Typography>
            <MenuButton
                label={<Typography variant="caption">{UIStrings.PageSizeLimit(pageSize)}</Typography>}
                menuItems={pageSizeMenuItems}
                buttonProps={{ id: 'pageSizeButton', disableRipple: true }}
                menuProps={{
                    open: false,
                    MenuListProps: {
                        dense: true,
                    },
                    slotProps: {
                        paper: {
                            sx: {
                                minWidth: theme.spacing(9.375),
                            },
                        },
                    },
                }}
            />
        </Box>
    );
};

export default TableNav;
