import React, { useMemo } from 'react';
import { CandidateCol, CellParams } from '.';
import { TestTypes } from '../../../../../../../Models/Configuration';
import UIStrings from '../../../../../../../Utils/UIStrings';
import { ScoreCell } from '../../cells';
import { PercentileScoreDTO } from '../../../../../../../Models/CandidateOverview';

const useTestColumns = (includedTests: TestTypes[]) => {
    return useMemo<CandidateCol[]>(() => {
        const getTestColumn = (test: TestTypes): [string, CandidateCol['accessorKey']] => {
            switch (test) {
                case TestTypes.Aptitude:
                    return [UIStrings.Aptitude, 'aptitudePercentileScore'];
                case TestTypes.EmotionalIntelligence:
                    return [UIStrings.EmotionalIntelligence, 'emotionalIntelligencePercentileScore'];
                case TestTypes.Personality:
                    return [UIStrings.Personality, 'personalityPercentileScore'];
                case TestTypes.Skills:
                    return [UIStrings.Skills, 'skillsPercentileScore'];
                default:
                    throw new Error('invalid test type');
            }
        };

        return includedTests.map((test): CandidateCol => {
            const [header, accessorKey] = getTestColumn(test);

            return {
                accessorKey,
                header,
                Cell: ({ cell }: CellParams) => <ScoreCell scoring={cell.getValue<PercentileScoreDTO>()} />,
                size: 120,
            };
        });
    }, [includedTests]);
};

export default useTestColumns;
