import { useState } from 'react';
import { useNotificationContext } from '../../../../Context/NotificationContext';
import { useAssessmentClient, useCandidatesClient } from '../../../../Hooks/ClientHooks';
import { ApplicantStatus } from '../../../../Models/CandidateOverview';
import { CandidateStatusModel } from '../../../../Models/CandidateStatusModel';
import UIStrings from '../../../../Utils/UIStrings';
import { CandidateDialogModel } from '../../Models/CandidateDialogModel';
import { ActionDialogContextModel, DialogEnum } from './ActionDialogContext';
import { TestTypes } from '../../../../Models/Configuration';

function useActionDialogContextValue(): ActionDialogContextModel {
    const candidatesClient = useCandidatesClient();
    const assessmentClient = useAssessmentClient();

    const { showSuccessToaster } = useNotificationContext();

    const [selectedCandidates, setSelectedCandidates] = useState<CandidateDialogModel[]>([]);
    const [candidateStatuses, setCandidateStatuses] = useState<CandidateStatusModel[]>([]);

    const [availableTestTypes, setAvailableTestTypes] = useState<Array<TestTypes>>([]);
    const [includeExperienceCriteria, setIncludeExperienceCriteria] = useState(false);

    const [openDialog, setOpenDialog] = useState<Map<DialogEnum, boolean>>(new Map<DialogEnum, boolean>());

    const [isLoading, setIsLoading] = useState({
        inviteForInterview: false,
        reject: false,
        hire: false,
    });

    const isDialogOpen = (status: DialogEnum) => {
        return openDialog.get(status) ?? false;
    };

    const triggerDialog = (status: DialogEnum, open: boolean) => {
        setOpenDialog((prevState) => {
            const nextState = new Map<DialogEnum, boolean>(prevState);
            nextState.set(status, open);
            return nextState;
        });
    };

    const showDialog = (
        c: CandidateDialogModel[],
        status: DialogEnum,
        testTypes?: Array<TestTypes>,
        shouldIncludeExperienceCriteria?: boolean,
    ) => {
        setSelectedCandidates(c);
        setAvailableTestTypes(testTypes || []);
        setIncludeExperienceCriteria(shouldIncludeExperienceCriteria ?? false);

        triggerDialog(status, true);
    };

    const hideDialog = (status: DialogEnum) => {
        triggerDialog(status, false);
    };

    const showHireNotification = () => {
        const candidate = selectedCandidates[0];
        if (!candidate) {
            return;
        }

        showSuccessToaster(`${candidate.name} ${UIStrings.HasBeenMarkedAsHired}`);
    };

    const removeCandidate = (candidate: CandidateDialogModel) => {
        setSelectedCandidates((prevState) => {
            return prevState.filter((x) => x.candidateId !== candidate.candidateId);
        });
    };

    const showRejectNotification = () => {
        const title =
            selectedCandidates.length > 1
                ? UIStrings.ApplicantsCount(selectedCandidates.length)
                : selectedCandidates[0].name;
        const message = selectedCandidates.length > 1 ? UIStrings.HaveBeenRejected : UIStrings.HasBeenRejected;

        showSuccessToaster(`${title} ${message}`);
    };

    const showInviteForInterviewNotification = () => {
        const candidate = selectedCandidates[0];
        if (!candidate) {
            return;
        }

        showSuccessToaster(`${candidate.name} ${UIStrings.HasBeenMarkedAsInvitedForInterviewing}`);
    };

    const inviteForInterviewApplicantAsync = async () => {
        setIsLoading((prev) => ({
            ...prev,
            inviteForInterview: true,
        }));
        const { assessmentId, candidateId } = selectedCandidates[0];

        await candidatesClient.inviteForInterviewApplicantAsync(assessmentId, candidateId);

        const nextState: CandidateStatusModel = {
            candidateId,
            candidateStatus: ApplicantStatus.InvitedForInterview,
            rejectedByEmail: false,
        };
        setCandidateStatuses([nextState]);
        setIsLoading((prev) => ({
            ...prev,
            inviteForInterview: false,
        }));
    };

    const rejectApplicantsAsync = async (sendEmail: boolean) => {
        setIsLoading((prev) => ({
            ...prev,
            reject: true,
        }));

        const nextStatuses = selectedCandidates.map((x) => {
            const item: CandidateStatusModel = {
                candidateId: x.candidateId,
                candidateStatus: ApplicantStatus.Rejected,
                rejectedByEmail: sendEmail,
            };

            return item;
        });
        const { assessmentId } = selectedCandidates[0];

        await candidatesClient.rejectApplicantsAsync(
            assessmentId,
            sendEmail,
            selectedCandidates.map((x) => {
                return x.candidateId;
            }),
        );

        setCandidateStatuses(nextStatuses);
        setIsLoading((prev) => ({
            ...prev,
            reject: false,
        }));
    };

    const hireApplicantAsync = async () => {
        setIsLoading((prev) => ({
            ...prev,
            hire: true,
        }));
        const { assessmentId, candidateId } = selectedCandidates[0];
        await candidatesClient.hireApplicantAsync(assessmentId, candidateId);

        const nextState: CandidateStatusModel = {
            candidateId,
            candidateStatus: ApplicantStatus.Hired,
            rejectedByEmail: false,
        };
        setCandidateStatuses([nextState]);
        setIsLoading((prev) => ({
            ...prev,
            hire: false,
        }));
    };

    const closeAssessmentAsync = async (assessmentId: string, rejectCandidates: boolean, sendEmail: boolean) => {
        await assessmentClient.closeAssessmentAsync(assessmentId, rejectCandidates, sendEmail);
    };

    return {
        selectedCandidates,
        candidateStatuses,

        removeCandidate,

        isDialogOpen,
        showDialog,
        hideDialog,

        inviteForInterviewApplicantAsync,
        rejectApplicantsAsync,
        hireApplicantAsync,
        closeAssessmentAsync,

        showInviteForInterviewNotification,
        showHireNotification,
        showRejectNotification,

        availableTestTypes,
        includeExperienceCriteria,

        isHireLoading: isLoading.hire,
        isInviteForInterviewLoading: isLoading.inviteForInterview,
        isRejectLoading: isLoading.reject,
    };
}

export default useActionDialogContextValue;
