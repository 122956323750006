import React, { useCallback, memo } from 'react';
import { Box } from '@talentmesh/core';
import { useTheme } from '@mui/material/styles';
import CheckboxRow from './CheckboxRow';
import BaseFilterPanelContent from './BaseFilterPanelContent';
import ApplicantsOverviewHiringStatusFilterEnum from '../../Models/ApplicantsOverviewHiringStatusFilterEnum';
import { useCandidatesOverviewContext } from '../../Contexts/CandidatesOverviewContext/CandidatesOverviewContext';

function HiringStatusFilterPanel(): JSX.Element {
    const theme = useTheme();
    const options = Object.values(ApplicantsOverviewHiringStatusFilterEnum);

    const { hiringStatusFilter, filterByHiringStatus } = useCandidatesOverviewContext();

    const filterSelection = useCallback(
        (value: string): Array<ApplicantsOverviewHiringStatusFilterEnum> => {
            return hiringStatusFilter.filter((item: string) => item === value);
        },
        [hiringStatusFilter],
    );

    const isSelected = useCallback(
        (value: string): boolean => {
            return filterSelection(value).length > 0;
        },
        [hiringStatusFilter],
    );

    const handleChange = useCallback(
        (value: number) => {
            let current: Array<ApplicantsOverviewHiringStatusFilterEnum>;

            if (isSelected(options[value])) {
                current = hiringStatusFilter.filter((item) => item !== options[value]);
            } else {
                current = [...hiringStatusFilter, options[value]];
            }

            filterByHiringStatus(current);
        },
        [hiringStatusFilter],
    );

    const reset = useCallback(() => {
        filterByHiringStatus([]);
    }, []);

    return (
        <BaseFilterPanelContent resetCallback={reset}>
            <Box sx={{ paddingBlock: theme.spacing(1.25) }}>
                {options.map((item, index) => (
                    <CheckboxRow
                        key={item}
                        id={item}
                        menuItemLabel={item}
                        selected={isSelected(item)}
                        changeCallback={() => handleChange(index)}
                        style={{ height: theme.spacing(5.25) }}
                    />
                ))}
            </Box>
        </BaseFilterPanelContent>
    );
}

export default memo(HiringStatusFilterPanel);
