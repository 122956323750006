import { useTheme } from '@mui/material';
import { Box, LinearProgress } from '@talentmesh/core';
import React from 'react';
import { useCandidatesTableContext } from '../../Contexts/CandidatesTableContext/CandidatesTableContext';

const TableLoadingOverlay = ({ children }: React.PropsWithChildren) => {
    const theme = useTheme();
    const table = useCandidatesTableContext();
    const { isLoading } = table.getState();

    return (
        <Box position="relative">
            {isLoading && (
                <Box position="absolute" width="100%" zIndex={theme.zIndex.drawer}>
                    <LinearProgress sx={{ width: '100%' }} />
                </Box>
            )}
            {children}
        </Box>
    );
};

export default TableLoadingOverlay;
