import { formatSalary } from '@talentmesh/core';
import InformationCategories from '../../../../../DTO/Assessments/InformationCategories';
import InformationTypes from '../../../../../DTO/Assessments/InformationTypes';
import SalaryExpectation from '../../../../../Models/SalaryExpectation';
import objectPropertiesToEnumValues from '../../../../../Utils/objectPropertiesToEnumValues';
import { ApplicantInformation } from '../../Models/ApplicantInformation';

export interface RequestInformationForm {
    PhoneNumber: boolean;
    NoticePeriod: boolean;
    ReasonForApplying: boolean;
    ReasonForLeaving: boolean;
    ValidWorkPermit: boolean;
    WhyWorkInStartup: boolean;
    RankRemunerationLearningBalance: boolean;
    StructureImportance: boolean;
    PersonalProject: boolean;
    WhereDoYouSeeYourself: boolean;
    SalaryExpectation: boolean;
}

export const DefaultRequestInformationForm: RequestInformationForm = {
    PhoneNumber: false,
    NoticePeriod: false,
    ReasonForApplying: false,
    ReasonForLeaving: false,
    ValidWorkPermit: false,
    WhyWorkInStartup: false,
    RankRemunerationLearningBalance: false,
    StructureImportance: false,
    PersonalProject: false,
    WhereDoYouSeeYourself: false,
    SalaryExpectation: false,
};

export const informationFormValues2Model = (form: RequestInformationForm): Array<ApplicantInformation> => {
    const informationTypes = objectPropertiesToEnumValues<RequestInformationForm, InformationTypes>(
        Object.entries(InformationTypes),
        form,
    );

    return informationTypes.map((informationType) => {
        return {
            informationCategory: InformationCategories.RecruitmentInformation,
            informationType,
        };
    });
};

const hasValue = (information: ApplicantInformation[], value: InformationTypes) => {
    return information.findIndex((x) => x.informationType === value) >= 0;
};

export const informationModel2FormValues = (information: ApplicantInformation[]): RequestInformationForm => {
    return {
        NoticePeriod: hasValue(information, InformationTypes.NoticePeriod),
        PhoneNumber: hasValue(information, InformationTypes.PhoneNumber),
        ReasonForApplying: hasValue(information, InformationTypes.ReasonForApplying),
        ReasonForLeaving: hasValue(information, InformationTypes.ReasonForLeaving),
        ValidWorkPermit: hasValue(information, InformationTypes.ValidWorkPermit),
        WhyWorkInStartup: hasValue(information, InformationTypes.WhyWorkInStartup),
        RankRemunerationLearningBalance: hasValue(information, InformationTypes.RankRemunerationLearningBalance),
        StructureImportance: hasValue(information, InformationTypes.StructureImportance),
        PersonalProject: hasValue(information, InformationTypes.PersonalProject),
        WhereDoYouSeeYourself: hasValue(information, InformationTypes.WhereDoYouSeeYourself),
        SalaryExpectation: hasValue(information, InformationTypes.SalaryExpectation),
    };
};

export const formatSalaryExpectation = (salaryExpectation: SalaryExpectation): string => {
    const {
        min,
        max,
        currency: { id },
    } = salaryExpectation;

    const formattedSalary = formatSalary({
        yearlySalaryFrom: Number.parseInt(min, 10),
        yearlySalaryTo: Number.parseInt(max, 10),
        currency: id,
    });

    return formattedSalary ?? '';
};
