import { useCandidatesClient } from '../../../Hooks/ClientHooks';
import { useNotificationContext } from '../../../Context/NotificationContext';
import UIStrings from '../../../Utils/UIStrings';

function useResendInvitation() {
    const candidatesClient = useCandidatesClient();
    const { showSuccessToaster, showFailToaster } = useNotificationContext();

    const resendInvitationAsync = async (
        assessmentId: string,
        candidateId: string,
        candidateFirstName: string,
        candidateLastName: string,
    ) => {
        try {
            await candidatesClient.resendInvitationAsync(assessmentId, candidateId);
            showSuccessToaster(UIStrings.InvitationHasBeenSentTo(candidateFirstName, candidateLastName));
        } catch {
            showFailToaster();
        }
    };

    return resendInvitationAsync;
}

export default useResendInvitation;
