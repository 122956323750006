import { useAuth0 } from '@auth0/auth0-react';
import React, { ReactNode, useEffect, useState, useMemo } from 'react';
import { useSettingsClient } from '../Hooks/ClientHooks';
import { AccountSettings, DefaultAccountSettings, EmailTemplateType, Notifications } from '../Models/Configuration';
import EmailTemplatePlaceholders from '../Pages/AccountSettings/Components/EmailTemplatePlaceholders';
import { AccountSettingsContext } from './AccountSettingsContext';
import { useCompanyContext } from './CompanyContext';

export interface AccountSettingsContextProviderProps {
    children: ReactNode;
}

function AccountSettingsProvider({ children }: AccountSettingsContextProviderProps): JSX.Element {
    const client = useSettingsClient();
    const [accountSettings, setAccountSettings] = useState<AccountSettings>(DefaultAccountSettings);
    const { currentCompanyProfile } = useCompanyContext();

    const loadConfigurationAsync = async () => {
        if (currentCompanyProfile.id !== '') {
            const settings = await client.getAccountSettingsAsync(currentCompanyProfile.id);
            setAccountSettings(settings);
        }
    };

    const { isAuthenticated } = useAuth0();

    useEffect(() => {
        loadConfigurationAsync();
    }, [isAuthenticated, currentCompanyProfile.id]);

    const [openPreview, setOpenPreview] = useState(false);

    const saveEmailTemplateAsync = async (
        type: EmailTemplateType,
        title: string,
        bodyHtml: string,
        regards: string,
        templateHtml: string,
    ) => {
        await client.saveEmailTemplateAsync({
            type,
            title,
            bodyHtml,
            regards,
            templateHtml,
            companyId: currentCompanyProfile.id,
        });

        setAccountSettings((prevState) => {
            const newState = { ...prevState };

            if (type === EmailTemplateType.InviteTemplate) {
                newState.inviteEmailTemplate = {
                    ...newState.inviteEmailTemplate,
                    title,
                    bodyHtml,
                    regards,
                    templateHtml,
                };
            }

            if (type === EmailTemplateType.RejectTemplate) {
                newState.rejectEmailTemplate = {
                    ...newState.rejectEmailTemplate,
                    title,
                    bodyHtml,
                    regards,
                    templateHtml,
                };
            }

            return newState;
        });
    };

    const patchNotificationsAsync = async (propertyName: keyof Notifications, value: boolean) => {
        await client.patchNotificationsSettingsAsync(currentCompanyProfile.id, propertyName, value);
        setAccountSettings((prev) => {
            return {
                ...prev,
                notifications: {
                    ...prev.notifications,
                    [propertyName]: value,
                },
            };
        });
    };

    const getEmailTemplateHtml = (
        title: string,
        thankYouMessage: string,
        bodyHtml: string,
        regards: string,
        candidateName: string,
        recruiterName: string,
        companyName: string,
        recruitmentName?: string,
        includeRecruiterName?: boolean,
    ) => {
        let thankYouMessageWithPlaceholders = `${thankYouMessage.replace(
            EmailTemplatePlaceholders.CompanyName,
            `${companyName}`,
        )}`;

        if (recruitmentName) {
            thankYouMessageWithPlaceholders = thankYouMessageWithPlaceholders.replace(
                EmailTemplatePlaceholders.RecruitmentName,
                recruitmentName,
            );
        }

        let html = `
            <p>${title} ${candidateName},</p>
            <p><br></p>
            <p>${thankYouMessageWithPlaceholders}</p>
            <p><br></p>
            ${bodyHtml}
            <p><br></p>
            <p>${regards}</p>
        `;

        if (includeRecruiterName) {
            html = `
            ${html}
            <p>${recruiterName}</p>
        `;
        }

        const withCompanyName = `
            ${html}
            <p>${companyName}</p>
        `;

        return withCompanyName;
    };

    const contextValue = useMemo(
        () => ({
            accountSettings,
            saveEmailTemplateAsync,
            patchNotificationsAsync,

            openPreview,
            setOpenPreview,

            getEmailTemplateHtml,
        }),
        [accountSettings, openPreview],
    );

    return <AccountSettingsContext.Provider value={contextValue}>{children}</AccountSettingsContext.Provider>;
}

export default AccountSettingsProvider;
