import { CandidateInviteDTO, inviteCandidateDTOFromCandidate } from '../DTO/Users/CandidateInviteDTO';
import { Candidate } from '../Models/Candidate';
import {
    CandidateAssessmentResults,
    DefaultCandidateAssessmentResults,
    mapCandidateAssessmentResultsDTO2Model,
} from '../Models/CandidateAssessmentResults';
import { post, put, toJson } from '../Utils/HTTP/HttpUtils';
import { CandidateAssessmentResultsDTO } from '../DTO/Assessments/CandidateAssessmentResultsDTO';
import ClientBase from './ClientBase';
import { CreateCandidateDTO } from '../DTO/Candidates/CreateCandidateDTO';
import { CandidateDTO, DefaultCandidateDTO } from '../DTO/Candidates/CandidateDTO';

export default class CandidatesClient extends ClientBase {
    async createCandidateAccountAsync(email: string): Promise<CandidateDTO> {
        const dto = { email } as CreateCandidateDTO;
        const strategy = toJson(dto);
        const response = await post<CandidateDTO>({
            path: this.addHost('users/candidates'),
            accessToken: await this.getAccessTokenSilently(),
            ...strategy,
        });
        if (response.ok && response.body) {
            return response.body;
        }
        return DefaultCandidateDTO;
    }

    async inviteCandidatesAsync(assessmentId: string, candidates: Array<Candidate>): Promise<void> {
        const dto = {
            assessmentId,
            invites: candidates.map((item) => inviteCandidateDTOFromCandidate(item)),
        };

        const strategy = toJson(dto);
        await post<CandidateInviteDTO>({
            path: this.addHost(`candidates/invite`),
            body: strategy.body,
            contentType: strategy.contentType,
            accessToken: await this.getAccessTokenSilently(),
        });
    }

    async resendInvitationAsync(assessmentId: string, candidateId: string): Promise<void> {
        const accessToken = await this.getAccessTokenSilently();
        const path = this.addHost(`candidates/${candidateId}/resend-invitation`);
        const strategy = toJson({ assessmentId });
        await post({
            path,
            accessToken,
            ...strategy,
        });
    }

    async inviteForInterviewApplicantAsync(recruitmentId: string, applicantId: string): Promise<void> {
        await this.updateCandidateStatusAsync(recruitmentId, applicantId, 'invite-for-interview');
    }

    async interviewApplicantAsync(recruitmentId: string, applicantId: string): Promise<void> {
        await this.updateCandidateStatusAsync(recruitmentId, applicantId, 'interview');
    }

    async shortlistApplicantAsync(recruitmentId: string, applicantId: string): Promise<void> {
        await this.updateCandidateStatusAsync(recruitmentId, applicantId, 'shortlist');
    }

    async referenceCheckApplicantAsync(recruitmentId: string, applicantId: string): Promise<void> {
        await this.updateCandidateStatusAsync(recruitmentId, applicantId, 'reference-check');
    }

    async offerApplicantAsync(recruitmentId: string, applicantId: string): Promise<void> {
        await this.updateCandidateStatusAsync(recruitmentId, applicantId, 'offer');
    }

    async backgroundCheckApplicantAsync(recruitmentId: string, applicantId: string): Promise<void> {
        await this.updateCandidateStatusAsync(recruitmentId, applicantId, 'background-check');
    }

    async hireApplicantAsync(recruitmentId: string, applicantId: string): Promise<void> {
        await this.updateCandidateStatusAsync(recruitmentId, applicantId, 'hire');
    }

    async removeApplicantAsync(recruitmentId: string, applicantId: string): Promise<void> {
        await this.updateCandidateStatusAsync(recruitmentId, applicantId, 'remove');
    }

    async rejectApplicantsAsync(assessmentId: string, sendEmail: boolean, candidateIds: string[]): Promise<void> {
        const accessToken = await this.getAccessTokenSilently();
        const path = this.addHost(`applicants/reject`);
        const strategy = toJson({ assessmentId, sendEmail, candidateIds });
        await post({
            path,
            accessToken,
            ...strategy,
        });
    }

    async updateCandidateStatusAsync(assessmentId: string, candidateId: string, statusPath: string): Promise<void> {
        const accessToken = await this.getAccessTokenSilently();
        const path = this.addHost(`applicants/${candidateId}/${statusPath}`);
        const strategy = toJson({ assessmentId });
        await post({
            path,
            accessToken,
            ...strategy,
        });
    }

    async saveFavoriteAsync(assessmentId: string, candidateId: string, isFavorite: boolean): Promise<void> {
        const accessToken = await this.getAccessTokenSilently();
        const path = this.addHost(`candidates/${candidateId}/save-favorite`);
        const strategy = toJson({ assessmentId, isFavorite });
        await put({
            path,
            accessToken,
            ...strategy,
        });
    }

    async resetApplicantAssessmentResultAsync(
        assessmentId: string,
        candidateId: string,
    ): Promise<CandidateAssessmentResults> {
        const accessToken = await this.getAccessTokenSilently();
        const path = this.addHost(`candidates/${candidateId}/reset`);
        const strategy = toJson({ assessmentId });
        const response = await post<CandidateAssessmentResultsDTO>({
            path,
            accessToken,
            ...strategy,
        });
        if (response.ok && response.body) {
            return mapCandidateAssessmentResultsDTO2Model(response.body);
        }
        return DefaultCandidateAssessmentResults;
    }
}
