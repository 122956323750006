import { useTheme } from '@mui/material';
import { Stack, Typography } from '@talentmesh/core';
import { FormikTextField } from '@talentmesh/forms';
import React from 'react';
import { useUserContext } from '../../../../Context/UserContext';
import UIStrings from '../../../../Utils/UIStrings';
import EmailTemplateToolTip from '../EmailTemplateToolTip';
import { useCompanyContext } from '../../../../Context/CompanyContext';

interface ClosureProps {
    showRecruiterName?: boolean;
}

const Closure = ({ showRecruiterName }: ClosureProps) => {
    const theme = useTheme();
    const { user } = useUserContext();
    const { currentCompanyProfile } = useCompanyContext();

    return (
        <>
            <FormikTextField sx={{ width: theme.spacing(34) }} name="regards" />
            {showRecruiterName && (
                <Stack
                    sx={{ paddingLeft: 1 }}
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    spacing={0.5}
                >
                    <Typography variant="body1">
                        {user?.firstName} {user?.lastName}
                    </Typography>
                    <EmailTemplateToolTip>{UIStrings.RecruiterNameTooltip}</EmailTemplateToolTip>
                </Stack>
            )}
            <Typography sx={{ paddingLeft: 1 }} variant="body1">
                {currentCompanyProfile.companyName}
            </Typography>
        </>
    );
};

export default Closure;
