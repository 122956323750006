import BlobData from '../Models/BlobData';
import ClientBase from './ClientBase';

export default class BlobClient extends ClientBase {
    async downloadCvAsync(assessmentId: string, applicantId: string): Promise<BlobData> {
        const response = await fetch(
            `${this.addHost('blob/cv')}?assessmentId=${assessmentId}&applicantId=${applicantId}`,
            {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${await this.getAccessTokenSilently()}`,
                },
            },
        );
        if (response.body && response.ok) {
            const disposition = response.headers.get('Content-Disposition');
            const filename = decodeURIComponent(disposition!.split("filename*=UTF-8''")[1]);

            return { blob: await response.blob(), filename };
        }

        return { blob: new Blob(), filename: '' };
    }
}
