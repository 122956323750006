import React from 'react';
import { useCandidatesOverviewContext } from '../Contexts/CandidatesOverviewContext/CandidatesOverviewContext';
import Dialogs from '../../../Dialogs/ATS/Dialogs';

interface CandidateOverviewDialogsProps {
    assessmentId: string;
}

function CandidateOverviewDialogs({ assessmentId }: CandidateOverviewDialogsProps): JSX.Element {
    const { assessmentName } = useCandidatesOverviewContext();
    return <Dialogs assessmentId={assessmentId} assessmentName={assessmentName} />;
}

export default CandidateOverviewDialogs;
