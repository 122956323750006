import React, { useCallback, memo } from 'react';
import { Box } from '@talentmesh/core';
import { useTheme } from '@mui/material/styles';
import CheckboxRow from './CheckboxRow';
import BaseFilterPanelContent from './BaseFilterPanelContent';
import ApplicantsOverviewApplicantStatusFilterEnum from '../../Models/ApplicantsOverviewApplicantStatusFilterEnum';
import { useCandidatesOverviewContext } from '../../Contexts/CandidatesOverviewContext/CandidatesOverviewContext';

function StatusFilterPanel(): JSX.Element {
    const theme = useTheme();
    const options = Object.values(ApplicantsOverviewApplicantStatusFilterEnum);

    const { filter, filterBy } = useCandidatesOverviewContext();

    const filterSelection = useCallback(
        (value: string): Array<ApplicantsOverviewApplicantStatusFilterEnum> => {
            return filterBy.filter((item: string) => item === value);
        },
        [filterBy],
    );

    const isSelected = useCallback(
        (value: string): boolean => {
            return filterSelection(value).length > 0;
        },
        [filterBy],
    );

    const handleChange = useCallback(
        (value: number) => {
            let current: Array<ApplicantsOverviewApplicantStatusFilterEnum>;

            if (isSelected(options[value])) {
                current = filterBy.filter((item) => item !== options[value]);
            } else {
                current = [...filterBy, options[value]];
            }

            filter(current);
        },
        [filterBy],
    );

    const reset = useCallback(() => {
        filter([]);
    }, []);

    return (
        <BaseFilterPanelContent resetCallback={reset}>
            <Box sx={{ paddingBlock: theme.spacing(1.25) }}>
                {options.map((item, index) => (
                    <CheckboxRow
                        key={item}
                        id={item}
                        menuItemLabel={item}
                        selected={isSelected(item)}
                        changeCallback={() => handleChange(index)}
                        style={{ height: theme.spacing(5.25) }}
                    />
                ))}
            </Box>
        </BaseFilterPanelContent>
    );
}

export default memo(StatusFilterPanel);
