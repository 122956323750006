enum ApplicantsOverviewSortByEnum {
    TalentScoreDesc,
    TalentScoreAsc,
    ExperienceCriteriaScoreDesc,
    ExperienceCriteriaScoreAsc,
    SkillsScoreDesc,
    SkillsScoreAsc,
    AptitudeScoreDesc,
    AptitudeScoreAsc,
    PersonalityScoreDesc,
    PersonalityScoreAsc,
    EmotionalIntelligenceScoreDesc,
    EmotionalIntelligenceScoreAsc,
    AppliedAtDesc,
    AppliedAtAsc,
    StartedAtDesc,
    StartedAtAsc,
    CompletedAtDesc,
    CompletedAtAsc,
    InvitedForInterviewAtDesc,
    InvitedForInterviewAtAsc,
    InterviewingAtDesc,
    InterviewingAtAsc,
}

export default ApplicantsOverviewSortByEnum;
