/* eslint-disable import/prefer-default-export */
import { ApplicantRecruitmentOverviewResponse } from '../../Models/CandidateOverview';
import { CandidateDialogModel } from './Models/CandidateDialogModel';

export function mapFromCandidateOverviewToCandidateDialogModel(
    assessmentId: string,
    overview: ApplicantRecruitmentOverviewResponse,
): CandidateDialogModel {
    return {
        ...overview,
        assessmentId,
        name: overview.candidateFullName,
        status: overview.applicantStatus,
    };
}
