enum HiringAction {
    Shortlist = 'Shortlist',
    InviteForInterview = 'InviteForInterview',
    Interview = 'Interview',
    ReferenceCheck = 'ReferenceCheck',
    Offer = 'Offer',
    BackgroundCheck = 'BackgroundCheck',
    Hire = 'Hire',
    Reject = 'Reject',
}

export default HiringAction;
