enum ApplicantsOverviewApplicantStatusFilterEnum {
    Favorite = 'Favorite',
    InvitedStatus = 'Invited',
    AppliedStatus = 'Applied',
    OngoingStatus = 'Ongoing',
    CompletedRetakenStatus = 'Completed / Retaken',
    InvitedForInterviewStatus = 'Invited for interview',
    InterviewingStatus = 'Interviewing',
}

export default ApplicantsOverviewApplicantStatusFilterEnum;
