import UIStrings from '../../../Utils/UIStrings';
import { TestTypes } from '../../../Models/Configuration';
import { ApplicantStatus, HiringStatus } from '../../../Models/CandidateOverview';

export function applicantStatusToString(applicantStatus: ApplicantStatus): string {
    return UIStrings[applicantStatus];
}

export function hiringStatusToString(hiringStatus: HiringStatus | null): string {
    if (hiringStatus) {
        return UIStrings[hiringStatus];
    }

    return UIStrings.EmptyHiringStatus;
}

export const mainTestTypesRenderingOrder: Array<TestTypes> = [
    TestTypes.Personality,
    TestTypes.Aptitude,
    TestTypes.Skills,
    TestTypes.EmotionalIntelligence,
];
