import { MRT_SortingState } from 'material-react-table';
import { useCallback, useState } from 'react';
import { ApplicantRecruitmentOverviewResponse } from '../../../../../../Models/CandidateOverview';
import ApplicantsOverviewSortByEnum from '../../../Models/ApplicantsOverviewSortByEnum';
import { CandidatesOverviewContextType } from '../../CandidatesOverviewContext/CandidatesOverviewContext';

const useManualSorting = (sort: CandidatesOverviewContextType['sort']) => {
    const [sorting, setSortingState] = useState<MRT_SortingState>([]);

    const columnSortToEnum = useCallback(({ desc, id }: MRT_SortingState[0]) => {
        const sortBy = ApplicantsOverviewSortByEnum;
        switch (id as keyof ApplicantRecruitmentOverviewResponse) {
            case 'talentScore':
                return desc ? sortBy.TalentScoreDesc : sortBy.TalentScoreAsc;
            case 'experienceCriteriaScore':
                return desc ? sortBy.ExperienceCriteriaScoreDesc : sortBy.ExperienceCriteriaScoreAsc;
            case 'aptitudePercentileScore':
                return desc ? sortBy.AptitudeScoreDesc : sortBy.AptitudeScoreAsc;
            case 'emotionalIntelligencePercentileScore':
                return desc ? sortBy.EmotionalIntelligenceScoreDesc : sortBy.EmotionalIntelligenceScoreAsc;
            case 'personalityPercentileScore':
                return desc ? sortBy.PersonalityScoreDesc : sortBy.PersonalityScoreAsc;
            case 'skillsPercentileScore':
                return desc ? sortBy.SkillsScoreDesc : sortBy.SkillsScoreAsc;
            case 'appliedAt':
                return desc ? sortBy.AppliedAtDesc : sortBy.AppliedAtAsc;
            case 'startedAt':
                return desc ? sortBy.StartedAtDesc : sortBy.StartedAtAsc;
            case 'completedAt':
                return desc ? sortBy.CompletedAtDesc : sortBy.CompletedAtAsc;
            case 'invitedForInterviewAt':
                return desc ? sortBy.InvitedForInterviewAtDesc : sortBy.InvitedForInterviewAtAsc;
            case 'interviewingAt':
                return desc ? sortBy.InterviewingAtDesc : sortBy.InterviewingAtAsc;
            default:
                throw new Error('invalid column');
        }
    }, []);

    const onSortingChange = useCallback<React.Dispatch<React.SetStateAction<MRT_SortingState>>>(
        (dispatcher) => {
            let newSortingState: MRT_SortingState;
            if (typeof dispatcher === 'function') {
                const result = dispatcher(sorting);
                setSortingState(result);
                newSortingState = result;
            } else {
                setSortingState(dispatcher);
                newSortingState = dispatcher;
            }

            sort(newSortingState.length ? columnSortToEnum(newSortingState[0]) : undefined);
        },
        [sorting, setSortingState],
    );

    return { sorting, onSortingChange };
};

export default useManualSorting;
