import { ApplicantStatus, HiringStatus } from '../../Models/CandidateOverview';
import HiringAction from '../../Models/HiringAction';

function isShortlistAllowed(candidateStatus: ApplicantStatus, hiringStatus: HiringStatus | null) {
    let isAllowedByApplicantStatus = false;

    switch (candidateStatus) {
        case ApplicantStatus.Applied:
        case ApplicantStatus.Ongoing:
        case ApplicantStatus.Completed:
        case ApplicantStatus.Retaken:
        case ApplicantStatus.InvitedForInterview:
        case ApplicantStatus.Interviewing:
            isAllowedByApplicantStatus = true;
            break;
        default:
            isAllowedByApplicantStatus = false;
            break;
    }

    const isAllowedByHiringStatus = hiringStatus !== HiringStatus.Shortlist;

    return isAllowedByApplicantStatus && isAllowedByHiringStatus;
}

function isInviteForInterviewAllowed(candidateStatus: ApplicantStatus, hiringStatus: HiringStatus | null) {
    let isAllowedByApplicantStatus = false;

    switch (candidateStatus) {
        case ApplicantStatus.Applied:
        case ApplicantStatus.Ongoing:
        case ApplicantStatus.Completed:
        case ApplicantStatus.Retaken:
            isAllowedByApplicantStatus = true;
            break;
        default:
            isAllowedByApplicantStatus = false;
            break;
    }

    const isAllowedByHiringStatus = hiringStatus !== HiringStatus.InviteForInterview;

    return isAllowedByApplicantStatus && isAllowedByHiringStatus;
}

function isInterviewAllowed(candidateStatus: ApplicantStatus, hiringStatus: HiringStatus | null) {
    let isAllowedByApplicantStatus = false;

    switch (candidateStatus) {
        case ApplicantStatus.Applied:
        case ApplicantStatus.Ongoing:
        case ApplicantStatus.Completed:
        case ApplicantStatus.Retaken:
        case ApplicantStatus.InvitedForInterview:
            isAllowedByApplicantStatus = true;
            break;
        default:
            isAllowedByApplicantStatus = false;
            break;
    }

    const isAllowedByHiringStatus = hiringStatus !== HiringStatus.Interview;

    return isAllowedByApplicantStatus && isAllowedByHiringStatus;
}

function isReferenceCheckAllowed(candidateStatus: ApplicantStatus, hiringStatus: HiringStatus | null) {
    let isAllowedByApplicantStatus = false;

    switch (candidateStatus) {
        case ApplicantStatus.Applied:
        case ApplicantStatus.Ongoing:
        case ApplicantStatus.Completed:
        case ApplicantStatus.Retaken:
        case ApplicantStatus.InvitedForInterview:
        case ApplicantStatus.Interviewing:
            isAllowedByApplicantStatus = true;
            break;
        default:
            isAllowedByApplicantStatus = false;
            break;
    }

    const isAllowedByHiringStatus = hiringStatus !== HiringStatus.ReferenceCheck;

    return isAllowedByApplicantStatus && isAllowedByHiringStatus;
}

function isOfferAllowed(candidateStatus: ApplicantStatus, hiringStatus: HiringStatus | null) {
    let isAllowedByApplicantStatus = false;

    switch (candidateStatus) {
        case ApplicantStatus.Applied:
        case ApplicantStatus.Ongoing:
        case ApplicantStatus.Completed:
        case ApplicantStatus.Retaken:
        case ApplicantStatus.InvitedForInterview:
        case ApplicantStatus.Interviewing:
            isAllowedByApplicantStatus = true;
            break;
        default:
            isAllowedByApplicantStatus = false;
            break;
    }

    const isAllowedByHiringStatus = hiringStatus !== HiringStatus.Offer;

    return isAllowedByApplicantStatus && isAllowedByHiringStatus;
}

function isBackgroundCheckAllowed(candidateStatus: ApplicantStatus, hiringStatus: HiringStatus | null) {
    let isAllowedByApplicantStatus = false;

    switch (candidateStatus) {
        case ApplicantStatus.Applied:
        case ApplicantStatus.Ongoing:
        case ApplicantStatus.Completed:
        case ApplicantStatus.Retaken:
        case ApplicantStatus.InvitedForInterview:
        case ApplicantStatus.Interviewing:
            isAllowedByApplicantStatus = true;
            break;
        default:
            isAllowedByApplicantStatus = false;
            break;
    }

    const isAllowedByHiringStatus = hiringStatus !== HiringStatus.BackgroundCheck;

    return isAllowedByApplicantStatus && isAllowedByHiringStatus;
}

function isRejectAllowed(candidateStatus: ApplicantStatus) {
    switch (candidateStatus) {
        case ApplicantStatus.Applied:
        case ApplicantStatus.Ongoing:
        case ApplicantStatus.Completed:
        case ApplicantStatus.Retaken:
        case ApplicantStatus.InvitedForInterview:
        case ApplicantStatus.Interviewing:
            return true;
        default:
            return false;
    }
}

function isHireAllowed(candidateStatus: ApplicantStatus) {
    switch (candidateStatus) {
        case ApplicantStatus.Applied:
        case ApplicantStatus.Ongoing:
        case ApplicantStatus.Completed:
        case ApplicantStatus.Retaken:
        case ApplicantStatus.InvitedForInterview:
        case ApplicantStatus.Interviewing:
            return true;
        default:
            return false;
    }
}

function isHiringActionAllowedInState(
    action: HiringAction,
    applicantStatus: ApplicantStatus,
    hiringStatus: HiringStatus | null,
): boolean {
    switch (action) {
        case HiringAction.Shortlist:
            return isShortlistAllowed(applicantStatus, hiringStatus);
        case HiringAction.InviteForInterview:
            return isInviteForInterviewAllowed(applicantStatus, hiringStatus);
        case HiringAction.Interview:
            return isInterviewAllowed(applicantStatus, hiringStatus);
        case HiringAction.ReferenceCheck:
            return isReferenceCheckAllowed(applicantStatus, hiringStatus);
        case HiringAction.Offer:
            return isOfferAllowed(applicantStatus, hiringStatus);
        case HiringAction.BackgroundCheck:
            return isBackgroundCheckAllowed(applicantStatus, hiringStatus);
        case HiringAction.Hire:
            return isHireAllowed(applicantStatus);
        case HiringAction.Reject:
            return isRejectAllowed(applicantStatus);
        default:
            // by default an action is not allowed
            return false;
    }
}

export default isHiringActionAllowedInState;
