import ViewColumnOutlinedIcon from '@mui/icons-material/ViewColumnOutlined';
import { useTheme } from '@mui/material';
import { Button } from '@talentmesh/core';
import { MRT_ShowHideColumnsMenu as MRTShowHideColumnsMenu } from 'material-react-table';
import React, { useState } from 'react';
import UIStrings from '../../../../../Utils/UIStrings';
import { useCandidatesTableContext } from '../../Contexts/CandidatesTableContext/CandidatesTableContext';

const EditColumnsButton = () => {
    const theme = useTheme();
    const table = useCandidatesTableContext();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleEditColClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    return (
        <>
            <Button variant="outlined" size="small" onClick={handleEditColClick} startIcon={<ViewColumnOutlinedIcon />}>
                {UIStrings.EditColumns}
            </Button>
            <MRTShowHideColumnsMenu
                table={table}
                anchorEl={anchorEl}
                setAnchorEl={setAnchorEl}
                MenuListProps={{
                    sx: {
                        '& > li': {
                            '& > div': {
                                flexDirection: 'row-reverse',
                                width: '100%',
                                // switch and label of the column
                                '& > label': { mr: 'auto' },
                                // drag icon
                                '& > button': { order: 1, color: theme.palette.grey[200] },
                                // pins
                                '& > div': {
                                    'button[aria-label="Unpin"]': { color: 'rgba(198, 198, 30, 1)' },
                                    button: { color: theme.palette.grey[200] },
                                },
                            },
                            '&:hover > div': {
                                // drag icon
                                '& > button': {
                                    color: theme.palette.grey[400],
                                    '&:hover': { color: theme.palette.grey[500] },
                                },
                                // pins
                                '& > div': {
                                    'button[aria-label="Pin to left"], button[aria-label="Pin to right"]': {
                                        color: theme.palette.grey[400],
                                        '&:hover': { color: theme.palette.grey[500] },
                                    },
                                },
                            },
                        },
                    },
                }}
            />
        </>
    );
};

export default EditColumnsButton;
