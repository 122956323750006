/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import { useTheme } from '@mui/material';
import React, { useState } from 'react';
import { Grid, Paper, Stack, Typography } from '@talentmesh/core';
import { useNotificationContext } from '../../../../../../../../Context/NotificationContext';
import ApplicantInformation from '../../../../../../../../Models/ApplicantInformation';
import UIStrings from '../../../../../../../../Utils/UIStrings';
import { useCandidatesResultsContext } from '../../../../../Contexts/CandidateResultsContext';
import BodyTypography from '../../../../Common/BodyTypography';
import DownloadButton from '../../../../Common/DownloadButton';
import getCvIcon from './Utils/getCvIcon';
import GridRow from '../Common/GridRow';
import downloadBlob from '../../../../../../Overview/Contexts/CandidatesTableContext/utils/downloadBlob';

interface ApplicantCvSectionProps {
    cv: ApplicantInformation;
}

function ApplicantCvSection({ cv }: ApplicantCvSectionProps): JSX.Element {
    const { results, downloadCvAsync } = useCandidatesResultsContext();
    const { candidateFirstName, candidateLastName } = results;
    // cv.answer is file extension
    const pdfFileName = UIStrings.FormFileName(`${candidateFirstName} ${candidateLastName}`, UIStrings.CV, cv.answer);
    const theme = useTheme();
    const [isCvDownloading, setIsCvDownloading] = useState<boolean>(false);
    const { showFailToaster, showSuccessToaster } = useNotificationContext();

    const onClickHandler = async () => {
        try {
            setIsCvDownloading(true);
            const { blob } = await downloadCvAsync();
            downloadBlob(pdfFileName, blob);
            showSuccessToaster(UIStrings.CvHasBeenDownloadedSuccessfully, UIStrings.DownloadSuccessful);
        } catch (error) {
            showFailToaster(UIStrings.SorryWeWereUnableToDownloadCv, UIStrings.DownloadFailed);
        } finally {
            setIsCvDownloading(false);
        }
    };

    return (
        <GridRow container item>
            <Grid item xs={3}>
                <BodyTypography color={theme.palette.text.secondary}>{UIStrings.CurriculumVitae}:</BodyTypography>
            </Grid>

            <Grid item xs={9}>
                <Paper
                    elevation={0}
                    sx={{
                        width: 'fit-content',
                        padding: theme.spacing(2.5),
                        border: `1px solid ${theme.palette.border.main}`,
                    }}
                >
                    <Stack direction="row" spacing={theme.spacing(4)} alignItems="center">
                        <Stack direction="row" spacing={theme.spacing(1.5)} alignItems="center">
                            {getCvIcon(cv.answer)}
                            <Typography variant="body2">{pdfFileName}</Typography>
                        </Stack>
                        <DownloadButton
                            onClick={onClickHandler}
                            label={UIStrings.DownloadCv}
                            loading={isCvDownloading}
                        />
                    </Stack>
                </Paper>
            </Grid>
        </GridRow>
    );
}

export default ApplicantCvSection;
