import { paperClasses, useTheme } from '@mui/material';
import { MenuButton, MenuItemWithValueProps, Typography } from '@talentmesh/core';
import React, { useMemo, useState } from 'react';
import { CellProps } from '.';
import { AssessmentStatuses } from '../../../../../../Models/AssessmentData';
import { ApplicantStatus } from '../../../../../../Models/CandidateOverview';
import HiringAction from '../../../../../../Models/HiringAction';
import isHiringActionAllowedInState from '../../../../../../Utils/ATSActions/isHiringActionAllowedInState';
import UIStrings from '../../../../../../Utils/UIStrings';
import { DialogEnum, useActionDialogContext } from '../../../../../Dialogs/ATS/Context/ActionDialogContext';
import { mapFromCandidateOverviewToCandidateDialogModel } from '../../../../../Dialogs/Dialogs.Utils';
import { hiringStatusToString } from '../../../../Utils/Utils';
import TooltipTypography from '../../../Components/TooltipTypography';
import { useCandidatesOverviewContext } from '../../CandidatesOverviewContext/CandidatesOverviewContext';
import { CellParams } from '../utils/useColumns';

interface HiringActionCellProps extends CellProps {
    column: CellParams['column'];
}

const HiringActionCell = ({ candidate, column }: HiringActionCellProps) => {
    const theme = useTheme();
    const {
        assessmentId,
        assessmentStatus,
        testTypes,
        includesExperienceCriteria,
        shortlistApplicantAsync,
        interviewApplicantAsync,
        referenceCheckApplicantAsync,
        offerApplicantAsync,
        backgroundCheckApplicantAsync,
        removeApplicantAsync,
    } = useCandidatesOverviewContext();
    const { showDialog } = useActionDialogContext();
    const [menuWidth, setMenuWidth] = useState(0);
    const { hiringStatus, applicantStatus } = candidate;

    const menuItems = useMemo<MenuItemWithValueProps[]>(() => {
        // when the assessment is closed, no actions are allowed
        if (assessmentStatus === AssessmentStatuses.Closed) {
            return [];
        }

        const handleOnClick = (action: DialogEnum) => () => {
            showDialog(
                [mapFromCandidateOverviewToCandidateDialogModel(assessmentId, candidate)],
                action,
                testTypes,
                includesExperienceCriteria,
            );
        };

        const hiringActions = [];

        // Remove is only allowed in Invited applicant status
        if (applicantStatus === ApplicantStatus.Invited) {
            hiringActions.push({
                menuItemLabel: UIStrings.Remove,
                onClick: () => removeApplicantAsync(candidate.candidateId),
            });
        }

        if (isHiringActionAllowedInState(HiringAction.Shortlist, applicantStatus, hiringStatus)) {
            hiringActions.push({
                menuItemLabel: UIStrings.Shortlist,
                onClick: () => shortlistApplicantAsync(candidate.candidateId),
            });
        }

        if (isHiringActionAllowedInState(HiringAction.InviteForInterview, applicantStatus, hiringStatus)) {
            hiringActions.push({
                menuItemLabel: UIStrings.InviteForInterview,
                onClick: handleOnClick(DialogEnum.InviteForInterview),
            });
        }

        if (isHiringActionAllowedInState(HiringAction.Interview, applicantStatus, hiringStatus)) {
            hiringActions.push({
                menuItemLabel: UIStrings.Interview,
                onClick: () => interviewApplicantAsync(candidate.candidateId),
            });
        }

        if (isHiringActionAllowedInState(HiringAction.ReferenceCheck, applicantStatus, hiringStatus)) {
            hiringActions.push({
                menuItemLabel: UIStrings.ReferenceCheck,
                onClick: () => referenceCheckApplicantAsync(candidate.candidateId),
            });
        }

        if (isHiringActionAllowedInState(HiringAction.Offer, applicantStatus, hiringStatus)) {
            hiringActions.push({
                menuItemLabel: UIStrings.Offer,
                onClick: () => offerApplicantAsync(candidate.candidateId),
            });
        }

        if (isHiringActionAllowedInState(HiringAction.BackgroundCheck, applicantStatus, hiringStatus)) {
            hiringActions.push({
                menuItemLabel: UIStrings.BackgroundCheck,
                onClick: () => backgroundCheckApplicantAsync(candidate.candidateId),
            });
        }

        if (isHiringActionAllowedInState(HiringAction.Hire, applicantStatus, hiringStatus)) {
            hiringActions.push({
                menuItemLabel: UIStrings.Hire,
                onClick: handleOnClick(DialogEnum.Hire),
            });
        }

        if (isHiringActionAllowedInState(HiringAction.Reject, applicantStatus, hiringStatus)) {
            hiringActions.push({
                menuItemLabel: UIStrings.Reject,
                onClick: handleOnClick(DialogEnum.Reject),
            });
        }

        return hiringActions;
    }, [applicantStatus, hiringStatus]);

    return menuItems.length === 0 ? (
        <TooltipTypography>{hiringStatusToString(hiringStatus)}</TooltipTypography>
    ) : (
        <MenuButton
            label={
                <Typography
                    variant="inherit"
                    overflow="hidden"
                    textOverflow="ellipsis"
                    whiteSpace="nowrap"
                    color={theme.palette.text.primary}
                    fontWeight={theme.typography.fontWeightRegular}
                >
                    {hiringStatusToString(hiringStatus)}
                </Typography>
            }
            menuItems={menuItems}
            menuProps={{ open: false, sx: { [`.${paperClasses.root}`]: { width: menuWidth } } }}
            buttonProps={{
                size: 'small',
                disabled: menuItems.length === 0,
                fullWidth: true,
                disableRipple: true,
                onClickCapture: () => setMenuWidth(column.getSize()),
                sx: { padding: 0, placeContent: 'space-between' },
            }}
        />
    );
};

export default HiringActionCell;
