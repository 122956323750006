import { useState } from 'react';
import { ToasterStatus } from '@talentmesh/core';
import { CandidatesResultsContextData } from './CandidateResultsContext';
import {
    useAssessmentClient,
    useBlobClient,
    useCandidatesClient,
    useExperienceCriteriaScoreClient,
} from '../../../../Hooks/ClientHooks';
import {
    CandidateAssessmentResults,
    DefaultCandidateAssessmentResults,
} from '../../../../Models/CandidateAssessmentResults';
import { JobCategory } from '../../../../Models/JobCategory';
import UIStrings from '../../../../Utils/UIStrings';
import { getFirstTest, getCandidateResultsTabValue, ResultTabType } from '../Utils/ResultsUtils';
import { getJobCategoryJobFunctionPair } from '../../../../Utils/JobFunctionSelectionUtils';
import { ExperienceCriterionFinalScore } from '../../../../Models/ExperienceCriterionFinalScore';
import UpdateExperienceCriteriaScoreDTO from '../../../../DTO/ExperienceCriteria/UpdateExperienceCriteriaScoreDTO';
import CreateExperienceCriteriaScoreDTO from '../../../../DTO/ExperienceCriteria/CreateExperienceCriteriaScoreDTO';
import NoteDTO from '../../../../DTO/Assessments/NoteDTO';

function useCandidatesResultsContextValue(): CandidatesResultsContextData {
    const assessmentClient = useAssessmentClient();
    const candidatesClient = useCandidatesClient();
    const experienceCriteriaScoreClient = useExperienceCriteriaScoreClient();
    const blobClient = useBlobClient();

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [assessmentId, setAssessmentId] = useState('');
    const [assessmentName, setAssessmentName] = useState('');
    const [candidateId, setCandidateId] = useState<string>('');
    const [results, setResults] = useState<CandidateAssessmentResults>(DefaultCandidateAssessmentResults);
    const { candidateFirstName } = results;

    const [openResetToaster, setOpenResetToaster] = useState<boolean>(false);
    const [resetToasterStatus, setResetToasterStatus] = useState<ToasterStatus>();
    const [resetToasterMessage, setResetToasterMessage] = useState<string>('');

    const [jobTitle, setJobTitle] = useState('');
    const [jobCategory, setJobCategory] = useState('');
    const [jobFunction, setJobFunction] = useState('');
    const [activeTestDetailsTab, setActiveTestDetailsTab] = useState(
        getCandidateResultsTabValue(ResultTabType.ApplicantInformation),
    );

    const [openCloseAssessmentConfirm, setOpenCloseAssessmentConfirm] = useState(false);

    const setJobInfo = (resultsJobFunctionId: number, jobCategories: JobCategory[]) => {
        const { jobCategoryName, jobFunctionName } = getJobCategoryJobFunctionPair(jobCategories, resultsJobFunctionId);
        setJobTitle(`${jobCategoryName}: ${jobFunctionName}`);
        setJobCategory(jobCategoryName);
        setJobFunction(jobFunctionName);
    };

    const loadCandidateResultsAsync = async (id: string, applicantId: string, jobCategories: JobCategory[]) => {
        setIsLoading(true);
        setAssessmentId(id);
        setCandidateId(applicantId);

        const response = await assessmentClient.getCandidateResults(id, applicantId);
        setResults(response);

        const spec = await assessmentClient.getSpecificationAsync(id);

        setAssessmentName(spec.name);

        setJobInfo(response.jobFunctionId, jobCategories);
        setActiveTestDetailsTab(getCandidateResultsTabValue(getFirstTest(response)));
        setIsLoading(false);
    };

    const updateIsFavorite = (isFavorite: boolean) => {
        setResults((prevState) => {
            const nextState = { ...prevState };
            nextState.isFavorite = isFavorite;
            return nextState;
        });
    };

    const resetAssessmentAsync = async () => {
        try {
            const nextState = await candidatesClient.resetApplicantAssessmentResultAsync(assessmentId, candidateId);

            setResults(nextState);
            setActiveTestDetailsTab(getCandidateResultsTabValue(ResultTabType.ApplicantInformation));
            setResetToasterStatus('success');
            setResetToasterMessage(UIStrings.successAssessmentResetToast(candidateFirstName));
        } catch {
            setResetToasterStatus('error');
            setResetToasterMessage(UIStrings.errorAssessmentResetToast(candidateFirstName));
        } finally {
            setOpenResetToaster(true);
        }
    };
    const downloadCvAsync = async () => await blobClient.downloadCvAsync(assessmentId, candidateId);

    const updateUserDefinedCriteriaScore: CandidatesResultsContextData['updateUserDefinedCriteriaScore'] = async (
        userDefinedScore: number,
        userDefinedJustification: string,
        criterionScore: ExperienceCriterionFinalScore,
    ) => {
        // When AI score generation fails criteria score is not yet created.
        // Therefore, if score id exists we update it otherwise create it.
        if (criterionScore.experienceCriteriaScoreId) {
            const updateDto: UpdateExperienceCriteriaScoreDTO = {
                userDefinedScore,
                userDefinedJustification,
            };

            await experienceCriteriaScoreClient.updateExperienceCriteriaScore(
                criterionScore.experienceCriteriaScoreId,
                updateDto,
            );
        } else {
            const createDto: CreateExperienceCriteriaScoreDTO = {
                experienceCriteriaId: criterionScore.experienceCriteriaId,
                applicantId: candidateId,
                userDefinedScore,
                userDefinedJustification,
            };

            await experienceCriteriaScoreClient.createExperienceCriteriaScore(createDto);
        }

        // Update of one of the criterion scores will issue recalculation of average experience score.
        // Update of the average experience score will issue recalculation of talent score.
        // To capture all updated scores we reload results
        const response = await assessmentClient.getCandidateResults(assessmentId, candidateId);
        setResults(response);
    };

    const updateNoteAsync = async (note: string) => {
        let response: NoteDTO | undefined;

        try {
            response = await assessmentClient.updateNoteAsync(assessmentId, candidateId, { note });
        } catch {
            throw new Error(UIStrings.NoteCannotBeSaved);
        } finally {
            if (response) {
                setResults({ ...results, ...response });
            }
        }
    };

    return {
        downloadCvAsync,
        assessmentId,
        assessmentName,
        isLoading,

        candidateId,

        jobTitle,
        jobCategory,
        jobFunction,
        activeTestDetailsTab,

        results,
        updateIsFavorite,
        loadCandidateResultsAsync,
        setActiveTestDetailsTab,

        openCloseAssessmentConfirm,
        setOpenCloseAssessmentConfirm,

        resetAssessmentAsync,
        openResetToaster,
        setOpenResetToaster,
        resetToasterStatus,
        setResetToasterStatus,
        resetToasterMessage,

        updateUserDefinedCriteriaScore,
        updateNoteAsync,
    };
}

export default useCandidatesResultsContextValue;
