import { useTheme } from '@mui/material/styles';
import { Box, Dialog, DialogProps, IconButton, Paper, Stack, Typography } from '@talentmesh/core';
import { CloseIcon } from '@talentmesh/icons';
import { useField } from 'formik';
import React from 'react';
import EmailTemplateViewer from '../../../Components/EmailTemplate/EmailTemplateViewer';
import { useAccountSettingsContext } from '../../../Context/AccountSettingsContext';
import { useUserContext } from '../../../Context/UserContext';
import UIStrings from '../../../Utils/UIStrings';
import { useCompanyContext } from '../../../Context/CompanyContext';
import { EmailTemplateType } from '../../../Models/Configuration';

export interface PreviewEmailDialogProps extends DialogProps {
    dialogTitle: string;
    bodyHtml: string;
    thankYouMessage: string;
    templateType: EmailTemplateType;
}

function PreviewEmailDialog({
    dialogTitle,
    bodyHtml,
    thankYouMessage,
    templateType,
    ...props
}: PreviewEmailDialogProps): JSX.Element {
    const theme = useTheme();
    const [fieldTitle] = useField('title');
    const [fieldRegards] = useField('regards');
    const { user } = useUserContext();
    const { currentCompanyProfile } = useCompanyContext();
    const { setOpenPreview, getEmailTemplateHtml } = useAccountSettingsContext();

    return (
        <Dialog
            {...props}
            PaperProps={{
                style: {
                    backgroundColor: 'transparent',
                    boxShadow: 'none',
                    maxWidth: 'unset',
                    width: 'auto',
                    height: 'auto',
                },
            }}
        >
            <Paper elevation={0} sx={{ width: theme.spacing(74), p: 2.5 }}>
                <Stack direction="column" spacing={2}>
                    <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={0}>
                        <Typography variant="h4" color={theme.palette.text.highlight}>
                            {dialogTitle}
                        </Typography>
                        <IconButton aria-label="close" onClick={() => setOpenPreview(false)} size="large">
                            <CloseIcon />
                        </IconButton>
                    </Stack>
                    <Paper elevation={0} sx={{ border: `1px solid ${theme.palette.border.main}`, p: 1.5 }}>
                        <Box sx={{ padding: theme.spacing(1.5) }}>
                            <EmailTemplateViewer
                                htmlTemplate={getEmailTemplateHtml(
                                    fieldTitle.value,
                                    thankYouMessage,
                                    bodyHtml,
                                    fieldRegards.value,
                                    UIStrings.ApplicantsNamePlaceholder,
                                    `${user?.firstName} ${user?.lastName}`,
                                    currentCompanyProfile.companyName,
                                    undefined,
                                    templateType !== EmailTemplateType.RejectTemplate,
                                )}
                            />
                        </Box>
                    </Paper>
                </Stack>
            </Paper>
        </Dialog>
    );
}

export default PreviewEmailDialog;
