import { dividerClasses, useTheme } from '@mui/material';
import { Typography } from '@talentmesh/core';
import { DragIndicatorIcon } from '@talentmesh/icons';
import { MRT_Row, MRT_TableInstance, MRT_TableState, useMaterialReactTable } from 'material-react-table';
import React, { createContext, useCallback, useContext, useEffect, useMemo } from 'react';
import { ApplicantRecruitmentOverviewResponse, CandidateTabs } from '../../../../../Models/CandidateOverview';
import UIStrings from '../../../../../Utils/UIStrings';
import canSelectCandidate from '../../Utils/CanSelectCandidate';
import { CandidatesOverviewContextType } from '../CandidatesOverviewContext/CandidatesOverviewContext';
import useColumns from './utils/useColumns';
import useManualSorting from './utils/useManualSorting';
import getPinnedCellStyles from './utils/useColumns/style';

type CandidatesTableState = Partial<MRT_TableState<ApplicantRecruitmentOverviewResponse>>;
type MRTStateMap = Map<string, CandidatesTableState>;

export const CandidatesTableContext = createContext<
    MRT_TableInstance<ApplicantRecruitmentOverviewResponse> | undefined
>(undefined);

export const useCandidatesTable = ({
    assessmentId,
    candidates,
    totalCandidatesCount,
    loading: isLoading,
    tabValue,
    testTypes,
    includesExperienceCriteria,
    sort,
}: CandidatesOverviewContextType) => {
    const theme = useTheme();
    const mrtStateKey = 'mrtState';
    const columns = useColumns(testTypes, includesExperienceCriteria);
    const { onSortingChange, sorting } = useManualSorting(sort);
    const enableRowSelection = useCallback(
        (row: MRT_Row<ApplicantRecruitmentOverviewResponse>) =>
            tabValue === CandidateTabs.Open && canSelectCandidate(row.original),
        [isLoading],
    );

    const initialState = useMemo<CandidatesTableState>(() => {
        const mrtStateStr = localStorage.getItem(mrtStateKey);
        const defaultState: Partial<CandidatesTableState> = {
            columnPinning: { left: ['mrt-row-select', 'isFavorite', 'applicant', 'applicantAction'] },
            columnVisibility: {
                phoneNumber: false,
                candidateEmail: false,
                noticePeriod: false,
                validWorkPermit: false,
                willingToRelocate: false,
                salaryExpectation: false,
                education: false,
                invitedAt: false,
                startedAt: false,
                invitedForInterviewAt: false,
                interviewingAt: false,
                hiredAt: false,
                rejectedAt: false,
            },
        };

        if (mrtStateStr) {
            const mrtStateMap: MRTStateMap = new Map(JSON.parse(mrtStateStr));
            return mrtStateMap.get(assessmentId) || defaultState;
        } else {
            return defaultState;
        }
    }, []);

    const table = useMaterialReactTable({
        columns,
        data: candidates,
        layoutMode: 'grid',
        autoResetPageIndex: true,
        enableColumnActions: false,
        enableTopToolbar: false,
        enableBottomToolbar: false,
        enableColumnOrdering: true,
        enableColumnPinning: true,
        enableColumnResizing: true,
        enableRowSelection,
        manualFiltering: true,
        manualPagination: true,
        manualSorting: true,
        sortDescFirst: true,
        onSortingChange,
        initialState,
        state: { density: 'compact', isLoading, sorting, showLoadingOverlay: false },
        rowCount: totalCandidatesCount,
        displayColumnDefOptions: {
            'mrt-row-select': {
                size: 52,
                enableHiding: false,
                enableColumnOrdering: true,
                muiTableHeadCellProps: { id: 'selectAll' },
            },
        },
        icons: { DragHandleIcon: DragIndicatorIcon },
        renderEmptyRowsFallback: () => (
            <Typography align="center" alignSelf="center" maxWidth="min(100vw, 100%)">
                {UIStrings.NoRows}
            </Typography>
        ),
        muiTopToolbarProps: {
            sx: { backgroundColor: theme.palette.grey[100], borderBottom: `solid 1px ${theme.palette.border.main}` },
        },
        muiTableHeadCellProps: ({ column }) => {
            return {
                sx: {
                    borderLeft: `0.5px solid ${theme.palette.border.main}`,
                    borderRight: `0.5px solid ${theme.palette.border.main}`,
                    borderBottom: `1px solid ${theme.palette.border.main}`,

                    backgroundColor: theme.palette.grey[100],
                    color: theme.palette.text.primary,
                    fontWeight: theme.typography.fontWeightRegular,
                    placeContent: 'center',
                    '&#selectAll': { '.Mui-TableHeadCell-Content': { justifyContent: 'center' } },
                    '.Mui-TableHeadCell-Content': {
                        justifyContent: 'space-between',

                        '.Mui-TableHeadCell-Content-Wrapper': {
                            display: '-webkit-box',
                            WebkitBoxOrient: 'vertical',
                            WebkitLineClamp: '2',
                            whiteSpace: 'normal',
                        },
                    },
                    '.Mui-TableHeadCell-ResizeHandle-Wrapper': {
                        position: 'absolute',
                        right: 0,
                        paddingLeft: 0,
                        [`.${dividerClasses.root}`]: { visibility: 'hidden' },
                    },
                    '&:hover .Mui-TableHeadCell-ResizeHandle-Wrapper': {
                        [`.${dividerClasses.root}`]: { visibility: 'visible' },
                    },
                    '&:first-of-type': {
                        borderLeftStyle: 'none',
                    },
                    '&:last-of-type': {
                        borderRightStyle: 'none',
                    },
                    // style pinned cells
                    ...getPinnedCellStyles({ column, theme }),
                },
            };
        },
        muiTableBodyProps: { sx: { borderBottom: `solid 1px ${theme.palette.border.main}` } },
        muiTablePaperProps: { elevation: 0, sx: { borderRadius: 0 } },
        muiSelectAllCheckboxProps: {
            disabled: tabValue !== CandidateTabs.Open,
            sx: { minWidth: 'inherit', mb: theme.spacing(1) },
        },
        muiSelectCheckboxProps: { sx: { mx: 'auto' } },
        muiTableBodyRowProps: {
            sx: { '&:hover': { 'td:after': { backgroundColor: theme.palette.background.silverSand } } },
        },
        muiTableBodyCellProps: ({ column }) => {
            return {
                sx: {
                    minHeight: 60,
                    // each cell has a border of 0.5px on left and right
                    // which makes the border between cells 1px
                    border: `0.5px ${theme.palette.border.main}`,
                    borderStyle: 'none solid none solid',
                    // For the first cell left border and last cell right border we set to none
                    // Instead the table border of 1px is applied making all borders unified
                    '&:first-of-type': {
                        borderLeftStyle: 'none',
                    },
                    '&:last-of-type': {
                        borderRightStyle: 'none',
                    },
                    // style pinned cells
                    ...getPinnedCellStyles({ column, theme }),
                },
            };
        },
    });

    useEffect(() => {
        return () => {
            let mrtStateMap: MRTStateMap = new Map();
            const mrtStateStr = localStorage.getItem(mrtStateKey);

            if (mrtStateStr) {
                mrtStateMap = new Map(JSON.parse(mrtStateStr));
            }

            // rowSelection only lives on the page, and shall not be persisted across
            const tableState: CandidatesTableState = { ...table.getState(), rowSelection: undefined };
            mrtStateMap.set(assessmentId, tableState);

            localStorage.setItem(mrtStateKey, JSON.stringify(Array.from(mrtStateMap.entries())));
        };
    }, []);

    return table;
};

export const useCandidatesTableContext = () => {
    const context = useContext(CandidatesTableContext);
    if (!context) {
        throw new Error('useCandidatesTableContext must be used within its provider');
    }
    return context;
};
