import React from 'react';
import DefaultAssessmentResetDialog from '../AssessmentResetDialog/AssessmentResetDialog';
import { DialogEnum, useActionDialogContext } from './Context/ActionDialogContext';
import { useCandidatesClient } from '../../../Hooks/ClientHooks';
import { useNotificationContext } from '../../../Context/NotificationContext';
import UIStrings from '../../../Utils/UIStrings';

const TableAssessmentResetDialog = () => {
    const candidatesClient = useCandidatesClient();
    const { isDialogOpen, hideDialog, selectedCandidates } = useActionDialogContext();
    const { showSuccessToaster, showFailToaster } = useNotificationContext();

    const handleConfirm = async () => {
        if (selectedCandidates[0]) {
            const { assessmentId, candidateId, candidateFirstName } = selectedCandidates[0];
            try {
                await candidatesClient.resetApplicantAssessmentResultAsync(assessmentId, candidateId);
                showSuccessToaster(UIStrings.successAssessmentResetToast(candidateFirstName));
            } catch {
                showFailToaster(UIStrings.errorAssessmentResetToast(candidateFirstName));
            }
        }
    };

    return (
        <DefaultAssessmentResetDialog
            open={isDialogOpen(DialogEnum.ResetAssessment)}
            setOpen={() => hideDialog(DialogEnum.ResetAssessment)}
            onConfirm={handleConfirm}
        />
    );
};

export default TableAssessmentResetDialog;
