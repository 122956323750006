// TODO: https://dev.azure.com/TalentMesh/TalentMesh/_sprints/taskboard/TalentMesh%20Team/TalentMesh/Sprint%2010?workitem=726

import { PercentileLevel } from '@talentmesh/core';
import { EducationDTO } from '../DTO/Assessments/EducationDTO';
import { LocationDetailsDTO } from '../DTO/Assessments/LocationDetailsDTO';
import { ExperienceDTO } from '../DTO/Users/ExperienceDTO';
import { TalentScore } from './TalentScore';
import ApplicantsOverviewPageDTO from '../DTO/Candidates/ApplicantsOverviewPageDTO';
import { ApplicantsPage } from './Page';

export enum ApplicantStatus {
    Invited = 'Invited',
    Applied = 'Applied',
    Ongoing = 'Ongoing',
    Completed = 'Completed',
    Retaken = 'Retaken',
    InvitedForInterview = 'InvitedForInterview',
    Interviewing = 'Interviewing',
    Rejected = 'Rejected',
    Hired = 'Hired',
}

export enum HiringStatus {
    Shortlist = 'Shortlist',
    InviteForInterview = 'InviteForInterview',
    Interview = 'Interview',
    ReferenceCheck = 'ReferenceCheck',
    Offer = 'Offer',
    BackgroundCheck = 'BackgroundCheck',
    Hire = 'Hire',
    Reject = 'Reject',
}

export enum CandidateTabs {
    Open = 'Open',
    Hired = 'Hired',
    Rejected = 'Rejected',
}

export interface PercentileScoreDTO {
    percentileScore: number;
    percentileLevel: PercentileLevel;
}

export interface ScoreDTO {
    score: number;
    scoreLevel: PercentileLevel;
}

export interface ApplicantRecruitmentOverviewResponse {
    id: string;
    candidateId: string;
    candidateFirstName: string;
    candidateLastName: string;
    candidateFullName: string;
    candidateEmail: string;

    isFavorite: boolean;
    isInternal: boolean;

    applicantStatus: ApplicantStatus;
    hiringStatus: HiringStatus | null;

    invitedAt?: string | null;
    appliedAt?: string | null;
    startedAt?: string | null;
    completedAt?: string | null;
    resetAt?: string | null;
    lastViewedAt?: string | null;
    invitedForInterviewAt?: string | null;
    interviewingAt?: string | null;
    rejectedAt?: string | null;
    hiredAt?: string | null;
    shortlistedAt?: string | null;
    referenceCheckAt?: string | null;
    offerAt?: string | null;
    backgroundCheckAt?: string | null;

    talentScore: TalentScore | null;
    experienceCriteriaScore: ScoreDTO | null;
    personalityPercentileScore: PercentileScoreDTO | null;
    aptitudePercentileScore: PercentileScoreDTO | null;
    skillsPercentileScore: PercentileScoreDTO | null;
    emotionalIntelligencePercentileScore: PercentileScoreDTO | null;

    willingToRelocate: boolean | null;
    phoneNumber: string | null;
    noticePeriod: string | null;
    validWorkPermit: string | null;
    salaryExpectation: string | null;

    locationDetails: LocationDetailsDTO | null;
    education: EducationDTO | null;
    experience: ExperienceDTO | null;
}

export const fromApplicantsOverview = (
    dto: ApplicantsOverviewPageDTO,
): ApplicantsPage<ApplicantRecruitmentOverviewResponse> => {
    const { page, totalOpen, totalHired, totalRejected } = dto;
    return {
        pagination: page.pagination,
        data: page.data,
        totalOpen,
        totalHired,
        totalRejected,
    };
};
