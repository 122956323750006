enum ApplicantsOverviewHiringStatusFilterEnum {
    Shortlist = 'Shortlist',
    InviteForInterview = 'Invite for interview',
    Interview = 'Interview',
    ReferenceCheck = 'Reference check',
    Offer = 'Offer',
    BackgroundCheck = 'Background check',
}

export default ApplicantsOverviewHiringStatusFilterEnum;
