import React from 'react';
import { MenuIconButton, MenuItemWithValueProps } from '@talentmesh/core';
import { MoreVertIcon, ResendInvitationIcon, RestartAltIcon } from '@talentmesh/icons';
import isResendInvitationAllowed from '../../../../../Utils/isResendInvitationAllowed';
import isResetAssessmentAllowedState from '../../../../../Utils/isResetAssessmentAllowedState';
import UIStrings from '../../../../../Utils/UIStrings';
import { useCandidatesResultsContext } from '../../Contexts/CandidateResultsContext';
import useResendInvitation from '../../../Hooks/useResendInvitation';

interface HeaderActionsProps {
    setOpenAssessmentResetDialog: (value: boolean) => void;
}

function HeaderActions({ setOpenAssessmentResetDialog }: HeaderActionsProps): JSX.Element {
    const resendInvitationAsync = useResendInvitation();
    const { results, candidateId, assessmentId } = useCandidatesResultsContext();
    const { completedAt, candidateStatus, candidateFirstName, candidateLastName } = results;

    const resendInvitationHandlerAsync = async () => {
        await resendInvitationAsync(assessmentId, candidateId, candidateFirstName, candidateLastName);
    };

    const resetAssessmentHandlerAsync = () => {
        setOpenAssessmentResetDialog(true);
    };

    const availableToAllAtsActions = [
        {
            menuItemLabel: UIStrings.ResendInvitation,
            icon: <ResendInvitationIcon color="secondary" />,
            onClick: resendInvitationHandlerAsync,
            dense: true,
            disabled: !isResendInvitationAllowed(candidateStatus, completedAt),
        },
        {
            menuItemLabel: UIStrings.ResetAssessment,
            icon: <RestartAltIcon color="secondary" />,
            onClick: resetAssessmentHandlerAsync,
            dense: true,
            disabled: results.isReset || !isResetAssessmentAllowedState(candidateStatus),
        },
    ];

    const resultsMoreMenuOptions: MenuItemWithValueProps[] = [];

    resultsMoreMenuOptions.push(...availableToAllAtsActions);

    return <MenuIconButton menuItems={resultsMoreMenuOptions} icon={<MoreVertIcon />} />;
}

export default HeaderActions;
