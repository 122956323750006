import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import { Page, LoadingOverlay } from '@talentmesh/core';
import { useTheme } from '@mui/material/styles';
import useCandidatesResultsContextValue from './Contexts/useCandidatesResultsContextValue';
import { CandidatesResultsContext } from './Contexts/CandidateResultsContext';
import ApplicantResultsHeader from './Components/ApplicantResultsHeader';
import ResultsTabContent from './Components/ResultsTabContent';
import { ActionDialogContext } from '../../Dialogs/ATS/Context/ActionDialogContext';
import useActionDialogContextValue from '../../Dialogs/ATS/Context/ActionDialogContextValue';
import TopBar from '../../../Components/TopBars/TopBar';
import withTalentMeshUserRequired from '../../../Routing/withTalentMeshUserRequired';
import withSignupChecks from '../../../Routing/withSignupChecks';
import { useAssessmentSettingsContext } from '../../../Context/AssessmentSettingsContext';
import withCompanyProfileRequired from '../../../Routing/withCompanyProfileRequired';
import ResultsPageDialogs from './Components/ResultsPageDialogs';

interface CandidateResultsPageParams {
    candidateId: string;
    assessmentId: string;
}

function CandidateResultsPage(): JSX.Element {
    const theme = useTheme();

    const contextValue = useCandidatesResultsContextValue();
    const dialogContextValue = useActionDialogContextValue();
    const { assessmentSettings } = useAssessmentSettingsContext();
    const { loadCandidateResultsAsync, isLoading } = contextValue;
    const { assessmentId, candidateId } = useParams<Partial<CandidateResultsPageParams>>();

    useEffect(() => {
        loadCandidateResultsAsync(assessmentId!, candidateId!, assessmentSettings.jobCategories);
    }, []);

    return (
        <CandidatesResultsContext.Provider value={contextValue}>
            <ActionDialogContext.Provider value={dialogContextValue}>
                <TopBar />
                {isLoading ? (
                    <LoadingOverlay />
                ) : (
                    <Page
                        header={<ApplicantResultsHeader />}
                        containerProps={{ disableGutters: true }}
                        sx={{ backgroundColor: theme.palette.background.aliciaBlue }}
                    >
                        <ResultsTabContent />
                        <ResultsPageDialogs />
                    </Page>
                )}
            </ActionDialogContext.Provider>
        </CandidatesResultsContext.Provider>
    );
}

export default withCompanyProfileRequired(withTalentMeshUserRequired(withSignupChecks(CandidateResultsPage)));
