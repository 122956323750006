import React from 'react';
import InviteForInterviewConfirm from './InviteForInterviewConfirm';
import InviteForInterviewDialog from './InviteForInterviewDialog';
import RejectDialog from './RejectDialog';
import HireDialog from './HireDialog';
import CongratsOnHireDialog from './CongratsOnHireDialog';
import TableAssessmentResetDialog from './TableAssessmentResetDialog';

interface DialogsProps {
    assessmentId: string;
    assessmentName: string;
}

function Dialogs({ assessmentId, assessmentName }: DialogsProps): JSX.Element {
    return (
        <>
            <RejectDialog assessmentName={assessmentName} />
            <InviteForInterviewDialog assessmentName={assessmentName} />
            <InviteForInterviewConfirm />
            <HireDialog />
            <TableAssessmentResetDialog />
            <CongratsOnHireDialog assessmentId={assessmentId} />
        </>
    );
}

export default Dialogs;
