import React, { useState } from 'react';
import { Box, LoadingButton, Stack, SpanTypography } from '@talentmesh/core';
import { useTheme } from '@mui/material/styles';
import UIStrings from '../../../../../Utils/UIStrings';
import { DialogEnum, useActionDialogContext } from '../../Context/ActionDialogContext';

function RejectActions(): JSX.Element {
    const theme = useTheme();
    const { selectedCandidates, showRejectNotification, hideDialog, rejectApplicantsAsync, isRejectLoading } =
        useActionDialogContext();
    const [willSendEmail, setWillSendEmail] = useState(false);

    const closeRejectDialog = () => {
        hideDialog(DialogEnum.Reject);
    };

    return (
        <Stack spacing={2.5}>
            {selectedCandidates.length > 1 && (
                <Box>
                    <SpanTypography variant="body2">{`${UIStrings.ClickOn} `}</SpanTypography>
                    <SpanTypography variant="body2">{`"${UIStrings.SendRejectionEmail}" `}</SpanTypography>
                    <SpanTypography variant="body2">{`${UIStrings.RejectNote} ${UIStrings.RejectNote2}`}</SpanTypography>
                </Box>
            )}
            <Stack direction="row" spacing={1.25}>
                <LoadingButton
                    size="large"
                    loading={isRejectLoading && willSendEmail}
                    variant="outlined"
                    sx={{
                        width: '50%',
                        color: theme.palette.secondary.main,
                        borderColor: theme.palette.secondary.main,
                    }}
                    onClick={async () => {
                        setWillSendEmail(true);
                        await rejectApplicantsAsync(true);
                        closeRejectDialog();
                        showRejectNotification();
                    }}
                >
                    {UIStrings.SendRejectionEmail}
                </LoadingButton>
                <LoadingButton
                    size="large"
                    loading={isRejectLoading && !willSendEmail}
                    color="error"
                    variant="contained"
                    sx={{ width: '50%', borderColor: theme.palette.error.main }}
                    onClick={async () => {
                        await rejectApplicantsAsync(false);
                        closeRejectDialog();
                        showRejectNotification();
                    }}
                >
                    {UIStrings.MarkAsRejected}
                </LoadingButton>
            </Stack>
        </Stack>
    );
}

export default RejectActions;
