import { useTheme } from '@mui/material';
import { LoadingButton, MailButton, Stack } from '@talentmesh/core';
import { OpenInNewIcon } from '@talentmesh/icons';
import React, { useEffect, useState } from 'react';
import { useAccountSettingsContext } from '../../../../../Context/AccountSettingsContext';
import { useUserContext } from '../../../../../Context/UserContext';
import UIStrings from '../../../../../Utils/UIStrings';
import { DialogEnum, useActionDialogContext } from '../../Context/ActionDialogContext';
import { useCompanyContext } from '../../../../../Context/CompanyContext';

function InviteForInterviewActions(): JSX.Element {
    const theme = useTheme();
    const { accountSettings, getEmailTemplateHtml } = useAccountSettingsContext();

    const { title, thankYouMessage, bodyHtml, regards } = accountSettings.inviteEmailTemplate;

    const [body, setBody] = useState('');
    const { user } = useUserContext();
    const { currentCompanyProfile } = useCompanyContext();
    const {
        selectedCandidates,
        showInviteForInterviewNotification,
        hideDialog,
        showDialog,
        inviteForInterviewApplicantAsync,
        isInviteForInterviewLoading: isInterviewLoading,
    } = useActionDialogContext();
    const candidate = selectedCandidates[0];

    /** for "mailto:" link protocol (can't support html [except thunderbird clients with html-body instead of body]) */
    const parseLexicalHtmlTemplateToPlainText = (lexicalHtmlStr: string) => {
        const parser = new DOMParser();
        const dom = parser.parseFromString(lexicalHtmlStr, 'text/html');
        let plainText = '';
        dom.body.childNodes.forEach((node) => {
            // whitespace between br
            if (node.nodeName === '#text') {
                return;
            }

            // list
            if (node.nodeName === 'OL' || node.nodeName === 'UL') {
                node.childNodes.forEach((listItemNode) => {
                    plainText += `${listItemNode.textContent}\n`;
                });

                return;
            }

            plainText += `${node.textContent}\n`;
        });

        return plainText.trimEnd();
    };

    useEffect(() => {
        setBody(
            parseLexicalHtmlTemplateToPlainText(
                getEmailTemplateHtml(
                    title,
                    thankYouMessage,
                    bodyHtml,
                    regards,
                    candidate.name,
                    `${user.firstName} ${user.lastName}`,
                    currentCompanyProfile.companyName,
                ),
            ),
        );
    }, [candidate]);

    const closeDialog = () => {
        hideDialog(DialogEnum.InviteForInterview);
    };

    return (
        <Stack direction="row" spacing={1.25}>
            <MailButton
                fullWidth
                color="secondary"
                variant="outlined"
                email={candidate.candidateEmail}
                subject={`${currentCompanyProfile.companyName} - ${UIStrings.InvitationForInterview}`}
                body={body}
                linkProps={{ sx: { width: '50%' } }}
                onClick={() => {
                    closeDialog();
                    showDialog([candidate], DialogEnum.InviteForInterviewConfirm);
                }}
                endIcon={<OpenInNewIcon style={{ color: theme.palette.secondary.main }} />}
            >
                {UIStrings.ComposeInvitationEmail}
            </MailButton>
            <LoadingButton
                size="large"
                color="primary"
                variant="contained"
                loading={isInterviewLoading}
                sx={{ width: '50%' }}
                onClick={async () => {
                    await inviteForInterviewApplicantAsync();
                    closeDialog();
                    showInviteForInterviewNotification();
                }}
            >
                {UIStrings.MarkAsInviteForInterview}
            </LoadingButton>
        </Stack>
    );
}

export default InviteForInterviewActions;
