import { useTheme } from '@mui/material/styles';
import { Button, IconButton, Stack, StackProps, Typography } from '@talentmesh/core';
import { CloseIcon, EditIcon } from '@talentmesh/icons';
import React from 'react';
import EmailTemplateViewer from '../../../../Components/EmailTemplate/EmailTemplateViewer';
import { useAccountSettingsContext } from '../../../../Context/AccountSettingsContext';
import UIStrings from '../../../../Utils/UIStrings';

interface DialogTemplateProps extends Pick<StackProps, 'sx'> {
    dialogTitle: string;
    note?: string;
    title: string;
    thankYouMessage: string;
    bodyHtml: string;
    regards: string;
    candidateName: string;
    recruiterName: string;
    companyName: string;
    recruitmentName: string;
    closeDialog: () => void;
    editEmailTemplate: () => void;
    includeRecruiterName: boolean;
    boxHeight?: string;
}

function DialogTemplate({
    dialogTitle,
    note,
    title,
    thankYouMessage,
    bodyHtml,
    regards,
    candidateName,
    recruiterName,
    companyName,
    recruitmentName,
    closeDialog,
    editEmailTemplate,
    boxHeight,
    includeRecruiterName,
    sx,
}: DialogTemplateProps): JSX.Element {
    const theme = useTheme();
    const { getEmailTemplateHtml } = useAccountSettingsContext();

    return (
        <Stack sx={{ padding: 2.5, ...sx }} spacing={1.25}>
            <Stack spacing={2.25}>
                <IconButton onClick={closeDialog} size="large" sx={{ padding: 0, placeSelf: 'end' }}>
                    <CloseIcon sx={{ color: theme.palette.text.menuTitle }} />
                </IconButton>
                <Stack spacing={1.25}>
                    <Typography variant="h4" color={theme.palette.text.menuTitle}>
                        {dialogTitle}
                    </Typography>
                    <EmailTemplateViewer
                        htmlTemplate={getEmailTemplateHtml(
                            title,
                            thankYouMessage,
                            bodyHtml,
                            regards,
                            candidateName,
                            recruiterName,
                            companyName,
                            recruitmentName,
                            includeRecruiterName,
                        )}
                        sx={{
                            height: boxHeight,
                            backgroundColor: theme.palette.common.white,
                            borderRadius: theme.shape.tinyBorderRadius,
                            border: `1px solid ${theme.palette.border.main}`,
                            ...theme.typography.body1,
                        }}
                    />
                </Stack>
            </Stack>
            {note && (
                <Stack direction="column" spacing={1.25}>
                    <Typography variant="body2" color={theme.palette.text.menuTitle}>
                        {note}
                    </Typography>
                    <Button
                        size="small"
                        sx={{
                            width: theme.spacing(22),
                            backgroundColor: theme.palette.common.white,
                            color: theme.palette.secondary.main,
                            borderColor: theme.palette.secondary.main,
                        }}
                        variant="outlined"
                        onClick={editEmailTemplate}
                        endIcon={<EditIcon />}
                    >
                        {UIStrings.EditEmailTemplate}
                    </Button>
                </Stack>
            )}
        </Stack>
    );
}

export default DialogTemplate;
