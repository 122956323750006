import { useTheme } from '@mui/material/styles';
import { MenuIconButton, MenuItemWithValueProps } from '@talentmesh/core';
import { MoreVertIcon } from '@talentmesh/icons';
import { SnackbarKey, useSnackbar } from 'notistack';
import React, { useMemo } from 'react';
import { useNotificationContext } from '../../../../../../Context/NotificationContext';
import { useAssessmentClient, useBlobClient } from '../../../../../../Hooks/ClientHooks';
import useMap from '../../../../../../Hooks/useMap';
import { ApplicantRecruitmentOverviewResponse, ApplicantStatus } from '../../../../../../Models/CandidateOverview';
import isResendInvitationAllowed from '../../../../../../Utils/isResendInvitationAllowed';
import isResetAssessmentAllowedState from '../../../../../../Utils/isResetAssessmentAllowedState';
import UIStrings from '../../../../../../Utils/UIStrings';
import { DialogEnum, useActionDialogContext } from '../../../../../Dialogs/ATS/Context/ActionDialogContext';
import { mapFromCandidateOverviewToCandidateDialogModel } from '../../../../../Dialogs/Dialogs.Utils';
import SnackbarMessageContent from '../../../../Results/Components/SnackbarMessageContent';
import { useCandidatesOverviewContext } from '../../CandidatesOverviewContext/CandidatesOverviewContext';
import downloadBlob from '../utils/downloadBlob';
import useResendInvitation from '../../../../Hooks/useResendInvitation';
import isFullReportDownloadDisabled from '../../../../../../Utils/isFullReportDownloadAllowed';
import isDownloadCVAllowed from '../../../../../../Utils/isDownloadCVAllowed';

type DownloadItemType = 'cv' | 'report';
interface MoreActionsCellProps {
    candidate: ApplicantRecruitmentOverviewResponse;
}

function MoreActionsCell({ candidate }: MoreActionsCellProps): JSX.Element {
    const theme = useTheme();
    const { showDialog } = useActionDialogContext();
    const resendInvitationAsync = useResendInvitation();
    const { candidateId, candidateFirstName, candidateLastName, applicantStatus, completedAt, talentScore } = candidate;
    const { assessmentId } = useCandidatesOverviewContext();
    const [loadingMap, actLoadingMap] = useMap<DownloadItemType, SnackbarKey>();
    const { closeSnackbar, enqueueSnackbar } = useSnackbar();
    const { showFailToaster, showSuccessToaster } = useNotificationContext();
    const blobClient = useBlobClient();
    const assessmentClient = useAssessmentClient();

    const resendInvitationItem: MenuItemWithValueProps = {
        menuItemLabel: UIStrings.ResendInvitation,
        disabled: !isResendInvitationAllowed(applicantStatus, completedAt),
        onClick: async () => {
            try {
                await resendInvitationAsync(assessmentId, candidateId, candidateFirstName, candidateLastName);
                showSuccessToaster(UIStrings.InvitationHasBeenSentTo(candidateFirstName, candidateLastName));
            } catch (error) {
                showFailToaster();
            }
        },
    };

    const resetAssessmentItem: MenuItemWithValueProps = {
        menuItemLabel: UIStrings.ResetAssessment,
        disabled: !isResetAssessmentAllowedState(applicantStatus),
        onClick: () =>
            showDialog(
                [mapFromCandidateOverviewToCandidateDialogModel(assessmentId, candidate)],
                DialogEnum.ResetAssessment,
            ),
    };

    const enqueueLoadingSnackbar = (fileType: string) =>
        enqueueSnackbar(
            <SnackbarMessageContent
                status="pending"
                message={UIStrings.FileDownloadPending(candidateFirstName, fileType)}
            />,
            { persist: true },
        );

    const downloadCvItem: MenuItemWithValueProps = {
        menuItemLabel: UIStrings.DownloadCv,
        disabled: loadingMap.has('cv'),
        onClick: async () => {
            if (loadingMap.has('cv')) {
                return;
            }

            actLoadingMap.set('cv', enqueueLoadingSnackbar(UIStrings.CV));

            try {
                const { blob, filename } = await blobClient.downloadCvAsync(assessmentId, candidateId);

                downloadBlob(filename, blob);
                showSuccessToaster(
                    UIStrings.FileHasBeenDownloadedSuccessfully(UIStrings.CV),
                    UIStrings.DownloadSuccessful,
                );
            } catch {
                showFailToaster(
                    UIStrings.SorryWeWereUnableToDownloadApplicantsFile(UIStrings.CV),
                    UIStrings.DownloadFailed,
                );
            } finally {
                closeSnackbar(loadingMap.get('cv'));
                actLoadingMap.remove('cv');
            }
        },
    };

    const downloadReportItem: MenuItemWithValueProps = {
        menuItemLabel: UIStrings.DownloadReport,
        disabled: loadingMap.has('report'),
        onClick: async () => {
            if (loadingMap.has('report')) {
                return;
            }

            actLoadingMap.set('report', enqueueLoadingSnackbar(UIStrings.Report));

            try {
                const candidateIdBase64 = btoa(candidateId);
                const blob = await assessmentClient.getCandidateResultsPdfReport(assessmentId, candidateIdBase64);

                downloadBlob(
                    UIStrings.FormFileName(`${candidateFirstName} ${candidateLastName}`, UIStrings.Report, '.pdf'),
                    blob,
                );
                showSuccessToaster(
                    UIStrings.FileHasBeenDownloadedSuccessfully(UIStrings.Report),
                    UIStrings.DownloadSuccessful,
                );
            } catch {
                showFailToaster(
                    UIStrings.SorryWeWereUnableToDownloadApplicantsFile(UIStrings.Report),
                    UIStrings.DownloadFailed,
                );
            } finally {
                closeSnackbar(loadingMap.get('report'));
                actLoadingMap.remove('report');
            }
        },
    };

    const menuItems: MenuItemWithValueProps[] = useMemo(() => {
        const items = [];

        if (isResendInvitationAllowed(applicantStatus, completedAt)) {
            items.push(resendInvitationItem);
        }

        if (isDownloadCVAllowed(applicantStatus)) {
            items.push(downloadCvItem);
        }

        if (isResetAssessmentAllowedState(applicantStatus)) {
            items.push(resetAssessmentItem);
        }

        if (!isFullReportDownloadDisabled(talentScore)) {
            items.push(downloadReportItem);
        }

        return items;
    }, [talentScore, applicantStatus, completedAt, loadingMap]);

    return (
        <MenuIconButton
            menuProps={{ open: false, MenuListProps: { dense: true } }}
            menuItems={menuItems}
            icon={<MoreVertIcon />}
            buttonProps={{ disabled: !menuItems.length, sx: { mx: 'auto', width: theme.spacing(0.625), padding: 0 } }}
        />
    );
}

export default MoreActionsCell;
