import { GridPaginationModel } from '@talentmesh/core';
import saveAs from 'file-saver';
import { SnackbarKey, SnackbarMessage, useSnackbar } from 'notistack';
import { SyntheticEvent, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { useDownloadReportStatusContext } from '../../../../../Context/UseDownloadReportStatusContext';
import { useAssessmentClient, useCandidatesClient } from '../../../../../Hooks/ClientHooks';
import AdvancedFilterTypes from '../../../../../Models/AdvancedFilterTypes';
import { AssessmentStatuses } from '../../../../../Models/AssessmentData';
import {
    ApplicantRecruitmentOverviewResponse,
    ApplicantStatus,
    CandidateTabs,
} from '../../../../../Models/CandidateOverview';
import { JobCategory } from '../../../../../Models/JobCategory';
import { formatStringToYYYYMMDD } from '../../../../../Utils/DateHelper';
import HttpError from '../../../../../Utils/HTTP/Errors/HttpError';
import HttpStatusCodes from '../../../../../Utils/HTTP/HttpStatusCodes';
import { getJobCategoryJobFunctionPair } from '../../../../../Utils/JobFunctionSelectionUtils';
import { SnackbarMessageStatus } from '../../../Results/Components/SnackbarMessageContent';
import { getSnackbarMessage, getSnackbarOptions } from '../../../Results/Utils/SnackbarUtils';
import {
    AdvancedFilter,
    mapAdvancedFilterDto2Model,
    mapAdvancedFilterModel2Dto,
    mapAdvancedFiltersToIdsByType,
} from '../../Models/AdvancedFilter';
import ApplicantsOverviewApplicantStatusFilterEnum from '../../Models/ApplicantsOverviewApplicantStatusFilterEnum';
import ApplicantsOverviewSortByEnum from '../../Models/ApplicantsOverviewSortByEnum';
import { sortByTestType } from '../../Utils/ContextUtils';
import { CandidatesOverviewContextState, CandidatesOverviewContextType } from './CandidatesOverviewContext';
import useCandidatesOverviewQueryParams, {
    CandidateOverviewQueryParams,
    CandidateOverviewQueryParamsSeparator,
} from './utils/useCandidatesOverviewQueryParams';
import ApplicantsOverviewHiringStatusFilterEnum from '../../Models/ApplicantsOverviewHiringStatusFilterEnum';

function useCandidatesOverviewContextValue(
    assessmentId: string,
    jobCategories: JobCategory[],
): CandidatesOverviewContextType {
    const assessmentClient = useAssessmentClient();
    const candidatesClient = useCandidatesClient();
    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = useCandidatesOverviewQueryParams();
    const notiStackContext = useSnackbar();
    const { downloads, start, complete, getKey } = useDownloadReportStatusContext();

    const initialAdvancedFilterIds = useMemo<Record<AdvancedFilterTypes, string[]>>(
        () => ({
            All: [],
            Country: queryParams.countries,
            FieldOfEducation: queryParams.fieldOfEducations,
            HighestLevelOfEducation: queryParams.highestLevelOfEducations,
            JobType: queryParams.jobTypes,
        }),
        [],
    );

    const [isLoading, setIsLoading] = useState({
        assessmentSpec: true,
        candidates: false,
    });

    const [state, setState] = useState<CandidatesOverviewContextState>({
        assessmentName: '',
        assessmentStatus: AssessmentStatuses.NonPublic,
        clientId: '',
        status: undefined,
        candidates: [],
        totalCandidatesCount: 0,
        openFilter: false,
        sortBy: undefined,
        filterBy: queryParams.filterBy,
        hiringStatusFilter: queryParams.hiringStatusFilter,
        search: queryParams.search,
        testTypes: [],
        selected: [],
        pageSize: 25,
        pageNumber: queryParams.pageNumber,
        totalOpen: 0,
        totalHired: 0,
        totalReject: 0,
        tabValue: queryParams.candidateTab,
        jobCategoryName: '',
        jobFunctionName: '',
        advancedFilters: [],
        includesExperienceCriteria: false,
    });

    const {
        assessmentName,
        assessmentStatus,
        clientId,
        status,
        candidates,
        totalCandidatesCount,
        openFilter,
        sortBy,
        filterBy,
        hiringStatusFilter,
        search,
        testTypes,
        selected,
        pageSize,
        pageNumber,
        totalOpen,
        totalHired,
        totalReject,
        tabValue,
        jobCategoryName,
        jobFunctionName,
        advancedFilters,
        includesExperienceCriteria,
    } = state;

    /** query param block */
    const selectedAdvancedFilters = useMemo(
        () => advancedFilters.filter((advanceFilter) => advanceFilter.selected),
        [advancedFilters],
    );

    const advancedFilterQuery = useMemo(
        () => ({
            countries: mapAdvancedFiltersToIdsByType(selectedAdvancedFilters, AdvancedFilterTypes.Country),
            jobTypes: mapAdvancedFiltersToIdsByType(selectedAdvancedFilters, AdvancedFilterTypes.JobType),
            fieldOfEducations: mapAdvancedFiltersToIdsByType(
                selectedAdvancedFilters,
                AdvancedFilterTypes.FieldOfEducation,
            ),
            highestLevelOfEducations: mapAdvancedFiltersToIdsByType(
                selectedAdvancedFilters,
                AdvancedFilterTypes.HighestLevelOfEducation,
            ),
        }),
        [selectedAdvancedFilters],
    );

    // this is responsible for adding applicant's overview filters to the
    // query parameters inside current url so filters are preserved between page change, refresh, etc
    useEffect(() => {
        // assign filters into the url after assessment spec is fully loaded
        if (isLoading.assessmentSpec) {
            return;
        }

        const params: Partial<Record<CandidateOverviewQueryParams, string>> = {
            page: pageNumber.toString(),
            tab: tabValue,
        };

        const assignFilterToParamsIfNotEmpty = (arr: any[], param: CandidateOverviewQueryParams) => {
            if (arr.length) {
                params[param] = arr.join(CandidateOverviewQueryParamsSeparator);
            }
        };

        assignFilterToParamsIfNotEmpty(filterBy, 'filterBy');
        assignFilterToParamsIfNotEmpty(hiringStatusFilter, 'hiringStatusFilter');
        assignFilterToParamsIfNotEmpty(advancedFilterQuery.countries, 'Country');
        assignFilterToParamsIfNotEmpty(advancedFilterQuery.fieldOfEducations, 'FieldOfEducation');
        assignFilterToParamsIfNotEmpty(advancedFilterQuery.highestLevelOfEducations, 'HighestLevelOfEducation');
        assignFilterToParamsIfNotEmpty(advancedFilterQuery.jobTypes, 'JobType');

        if (search) {
            params.search = search;
        }

        navigate({ pathname: location.pathname, search: new URLSearchParams(params).toString() }, { replace: true });
    }, [pageNumber, tabValue, filterBy, hiringStatusFilter, advancedFilterQuery, search, isLoading.assessmentSpec]);

    // this is for opening up the filter menu if initial value from query params is present
    useEffect(() => {
        if (
            queryParams.filterBy.length ||
            queryParams.hiringStatusFilter.length ||
            queryParams.countries.length ||
            queryParams.fieldOfEducations.length ||
            queryParams.highestLevelOfEducations.length ||
            queryParams.jobTypes.length
        ) {
            setState((p) => ({
                ...p,
                openFilter: true,
            }));
        }
    }, [
        queryParams.filterBy,
        queryParams.hiringStatusFilter,
        queryParams.countries,
        queryParams.fieldOfEducations,
        queryParams.highestLevelOfEducations,
        queryParams.jobTypes,
    ]);
    /** query param block */

    const loadPageAsync = async () => {
        if (isLoading.assessmentSpec) {
            return;
        }

        setIsLoading((p) => ({ ...p, candidates: true }));

        const currentStatus = (tabValue === CandidateTabs.Open ? undefined : `${tabValue}`) as ApplicantStatus;

        try {
            const page = await assessmentClient.getApplicantsOverviewAsync(
                assessmentId,
                pageSize * pageNumber,
                pageSize,
                filterBy,
                hiringStatusFilter,
                search,
                currentStatus,
                sortBy,
                selectedAdvancedFilters.map(mapAdvancedFilterModel2Dto),
            );

            setState((prev) => {
                return {
                    ...prev,
                    status: currentStatus,
                    totalCandidatesCount: page.pagination.totalCount,
                    candidates: page.data,
                    selected: [],
                    totalOpen: page.totalOpen,
                    totalHired: page.totalHired,
                    totalReject: page.totalRejected,
                };
            });
        } finally {
            setIsLoading((p) => ({ ...p, candidates: false }));
        }
    };

    useEffect(() => {
        const doLoadCandidates = async () => await loadPageAsync();
        doLoadCandidates();
    }, [status, sortBy, filterBy, hiringStatusFilter, search, pageNumber, pageSize, advancedFilters]);

    const loadSpecificationAsync = async () => {
        const spec = await assessmentClient.getSpecificationAsync(assessmentId);
        const jobPair = getJobCategoryJobFunctionPair(jobCategories, spec.jobFunctionId);

        const filters = await assessmentClient.getAdvancedFilters(assessmentId);
        const mappedAdvancedFilters = filters.map(mapAdvancedFilterDto2Model);
        const selectedByInitialAdvancedFilters = mappedAdvancedFilters.map((filter) => ({
            ...filter,
            selected: initialAdvancedFilterIds[filter.filterType].includes(filter.id),
        }));

        setIsLoading((p) => ({ ...p, assessmentSpec: false, candidates: true }));
        setState((prev) => {
            return {
                ...prev,
                assessmentName: spec.name,
                assessmentStatus: spec.assessmentStatus,
                clientId: spec.clientId,
                testTypes: spec.includedTests.sort(sortByTestType),
                jobCategoryName: jobPair.jobCategoryName,
                jobFunctionName: jobPair.jobFunctionName,
                advancedFilters: selectedByInitialAdvancedFilters,
                includesExperienceCriteria: spec.includesExperienceCriteria,
            };
        });
    };

    useEffect(() => {
        loadSpecificationAsync();
    }, []);

    const sort = (value?: ApplicantsOverviewSortByEnum) => {
        setState((prev) => {
            return {
                ...prev,
                pageNumber: 0,
                sortBy: value,
            };
        });
    };

    const filter = (values: ApplicantsOverviewApplicantStatusFilterEnum[]) => {
        setState((prev) => {
            return {
                ...prev,
                pageNumber: 0,
                filterBy: values,
            };
        });
    };

    const filterByHiringStatus = (values: ApplicantsOverviewHiringStatusFilterEnum[]) => {
        setState((prev) => {
            return {
                ...prev,
                pageNumber: 0,
                hiringStatusFilter: values,
            };
        });
    };

    const searchByName = (name: string) => {
        setState((prev) => {
            return {
                ...prev,
                pageNumber: 0,
                search: name,
            };
        });
    };

    const saveFavoriteAsync = async (candidateId: string, isFavorite: boolean) => {
        await candidatesClient.saveFavoriteAsync(assessmentId, candidateId, isFavorite);

        const map = (item: ApplicantRecruitmentOverviewResponse) => {
            if (item.candidateId === candidateId) {
                return { ...item, isFavorite };
            }
            return item;
        };

        setState((prev) => {
            return {
                ...prev,
                candidates: prev.candidates.map((item) => map(item)),
            };
        });
    };

    const handlePaginationModelChange = (model: GridPaginationModel) =>
        setState((prev) => {
            return {
                ...prev,
                pageNumber: model.page,
                pageSize: model.pageSize,
            };
        });

    const setSelected = (values: Array<ApplicantRecruitmentOverviewResponse>) => {
        setState((prev) => {
            return {
                ...prev,
                selected: values,
            };
        });
    };

    const resetAdvancedFilterSelectedValues = (): AdvancedFilter[] => {
        return advancedFilters.map((item: AdvancedFilter) => {
            return { ...item, selected: false };
        });
    };

    const handleTabChange = (_: SyntheticEvent, value: CandidateTabs) => {
        const nextStatus = (value === CandidateTabs.Open ? undefined : `${value}`) as ApplicantStatus;

        setState((prev) => {
            return {
                ...prev,
                pageNumber: 0,
                tabValue: value,
                status: nextStatus,
                openFilter: false,
                filterBy: [],
                hiringStatusFilter: [],
                advancedFilters: resetAdvancedFilterSelectedValues(),
            };
        });
    };

    const toggleOpenFilter = (): void => {
        setState((prev) => {
            return {
                ...prev,
                openFilter: !prev.openFilter,
            };
        });
    };

    const compareFilters = (a: AdvancedFilter, b: AdvancedFilter): boolean => {
        return a.id === b.id && a.filterType === b.filterType;
    };

    const updateAdvancedFilter = (newFilter: AdvancedFilter) => {
        setState((prev) => {
            const newAdvancedFilters = prev.advancedFilters.filter((x) => !compareFilters(x, newFilter));
            newAdvancedFilters.push(newFilter);

            return {
                ...prev,
                advancedFilters: newAdvancedFilters,
            };
        });
    };

    const resetAdvancedFilterByType = (filterType: AdvancedFilterTypes) => {
        setState((prev) => {
            const resetFilters = prev.advancedFilters
                .filter((x) => x.filterType === filterType)
                .map((filters: AdvancedFilter): AdvancedFilter => {
                    return { ...filters, selected: false };
                });

            const currentFilters = prev.advancedFilters.filter((x) => x.filterType !== filterType);

            return {
                ...prev,
                advancedFilters: [...currentFilters, ...resetFilters],
            };
        });
    };

    const resetAdvancedFilter = () => {
        setState((prev) => {
            return {
                ...prev,
                filterBy: [],
                hiringStatusFilter: [],
                advancedFilters: resetAdvancedFilterSelectedValues(),
            };
        });
    };

    const invokeATSAction = async (hiringAction: () => Promise<void>) => {
        setIsLoading((p) => ({ ...p, candidates: true }));

        try {
            await hiringAction();
            await loadPageAsync();
        } finally {
            setIsLoading((p) => ({ ...p, candidates: false }));
        }
    };

    const shortlistApplicantAsync = async (applicantId: string) => {
        await invokeATSAction(() => candidatesClient.shortlistApplicantAsync(assessmentId, applicantId));
    };

    const interviewApplicantAsync = async (applicantId: string) => {
        await invokeATSAction(() => candidatesClient.interviewApplicantAsync(assessmentId, applicantId));
    };

    const referenceCheckApplicantAsync = async (applicantId: string) => {
        await invokeATSAction(() => candidatesClient.referenceCheckApplicantAsync(assessmentId, applicantId));
    };

    const offerApplicantAsync = async (applicantId: string) => {
        await invokeATSAction(() => candidatesClient.offerApplicantAsync(assessmentId, applicantId));
    };

    const backgroundCheckApplicantAsync = async (applicantId: string) => {
        await invokeATSAction(() => candidatesClient.backgroundCheckApplicantAsync(assessmentId, applicantId));
    };

    const removeApplicantAsync = async (applicantId: string) => {
        await invokeATSAction(() => candidatesClient.removeApplicantAsync(assessmentId, applicantId));
    };

    const getApplicantGroupReportResults = async () => {
        const candidateIdSubstituteForKey = 'group';
        const applicantIds = selected.map((applicant) => applicant.candidateId);
        const pendingMessage = getSnackbarMessage(
            candidateIdSubstituteForKey,
            'pending' as SnackbarMessageStatus,
            undefined,
            true,
        );
        const messageId = notiStackContext.enqueueSnackbar(pendingMessage, getSnackbarOptions('default'));

        const obtainSnackbarMessage = (statusMessage: string, key: SnackbarKey): SnackbarMessage => {
            return getSnackbarMessage(candidateIdSubstituteForKey, statusMessage as SnackbarMessageStatus, key, true);
        };

        try {
            start(assessmentId, candidateIdSubstituteForKey);
            const blob = await assessmentClient.getApplicantGroupReportResults(assessmentId, applicantIds);

            notiStackContext.closeSnackbar(messageId);
            notiStackContext.enqueueSnackbar(null, {
                content: (key) => obtainSnackbarMessage('success', key),
            });

            saveAs(
                blob,
                `${formatStringToYYYYMMDD(new Date().toUTCString())} - Applicant Shortlist - ${assessmentName}.pdf`,
            );
        } catch (exception) {
            const error = exception as HttpError;

            if (error.status === HttpStatusCodes.TooManyRequests) {
                notiStackContext.closeSnackbar(messageId);
                notiStackContext.enqueueSnackbar(null, {
                    content: (key) => obtainSnackbarMessage('warning', key),
                });
            } else {
                notiStackContext.closeSnackbar(messageId);
                notiStackContext.enqueueSnackbar(null, {
                    content: (key) => obtainSnackbarMessage('error', key),
                });
            }
        } finally {
            complete(assessmentId, candidateIdSubstituteForKey);
        }
    };

    return {
        assessmentId,
        assessmentName,
        clientId,
        assessmentStatus,
        candidates,
        loading: isLoading.assessmentSpec || isLoading.candidates,
        openFilter,
        totalOpen,
        totalHired,
        totalReject,
        includesExperienceCriteria,

        testTypes,

        sort,
        filter,
        filterByHiringStatus,
        searchByName,

        saveFavoriteAsync,

        selected,
        setSelected,

        loadPageAsync,
        totalCandidatesCount,

        pageSize,
        pageNumber,
        handlePaginationModelChange,

        filterBy,
        hiringStatusFilter,

        tabValue,
        handleTabChange,
        jobCategoryName,
        jobFunctionName,
        toggleOpenFilter,
        advancedFilters,
        updateAdvancedFilter,
        resetAdvancedFilterByType,
        resetAdvancedFilter,
        search,
        shortlistApplicantAsync,
        interviewApplicantAsync,
        referenceCheckApplicantAsync,
        offerApplicantAsync,
        backgroundCheckApplicantAsync,
        removeApplicantAsync,
        groupReportProcessing: downloads.has(getKey(assessmentId, 'group')),
        getApplicantGroupReportResults,
    };
}

export default useCandidatesOverviewContextValue;
