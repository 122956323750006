import { createContext, useContext } from 'react';
import { CandidateDialogModel } from '../../Models/CandidateDialogModel';
import { TestTypes } from '../../../../Models/Configuration';
import { CandidateStatusModel } from '../../../../Models/CandidateStatusModel';

export enum DialogEnum {
    InviteForInterview,
    InviteForInterviewConfirm,
    Reject,
    Hire,
    CongratsOnHire,
    ResetAssessment,
}

export interface ActionDialogContextModel {
    selectedCandidates: CandidateDialogModel[];
    candidateStatuses: CandidateStatusModel[];

    removeCandidate: (candidate: CandidateDialogModel) => void;

    isDialogOpen: (status: DialogEnum) => boolean;
    showDialog: (
        c: CandidateDialogModel[],
        status: DialogEnum,
        testTypes?: Array<TestTypes>,
        includeExperienceCriteria?: boolean,
    ) => void;
    hideDialog: (status: DialogEnum) => void;

    inviteForInterviewApplicantAsync: () => Promise<void>;
    rejectApplicantsAsync: (sendEmail: boolean) => Promise<void>;
    hireApplicantAsync: () => Promise<void>;
    closeAssessmentAsync: (assessmentId: string, rejectCandidates: boolean, sendEmail: boolean) => Promise<void>;

    showInviteForInterviewNotification: () => void;
    showHireNotification: () => void;
    showRejectNotification: () => void;

    availableTestTypes: Array<TestTypes>;
    includeExperienceCriteria: boolean;

    isInviteForInterviewLoading: boolean;
    isRejectLoading: boolean;
    isHireLoading: boolean;
}

export const ActionDialogContext = createContext<ActionDialogContextModel | undefined>(undefined);

export function useActionDialogContext() {
    const context = useContext(ActionDialogContext);
    if (!context) {
        throw new Error('useActionDialogContext must be used within the ActionDialogContext.Provider');
    }
    return context;
}
