import { useTheme } from '@mui/material/styles';
import React from 'react';
import { LoadingButton } from '@talentmesh/core';
import { resourceBaseUrl } from '../../../AppSettings';
import UIStrings from '../../../Utils/UIStrings';
import ActionConfirmationDialog from './Components/ActionConfirmationDialog';
import DialogCardContainer from './Components/DialogCardContainer';
import DialogHeader from './Components/DialogHeader';
import InterviewConfirmDialogBody from './Components/InterviewConfirm/InterviewConfirmDialogBody';
import InterviewConfirmDialogHeader from './Components/InterviewConfirm/InterviewConfirmDialogHeader';
import { DialogEnum, useActionDialogContext } from './Context/ActionDialogContext';

function InviteForInterviewConfirm(): JSX.Element {
    const theme = useTheme();
    const {
        selectedCandidates,
        showInviteForInterviewNotification,
        hideDialog,
        inviteForInterviewApplicantAsync,
        isInviteForInterviewLoading,
    } = useActionDialogContext();

    const closeConfirm = () => {
        hideDialog(DialogEnum.InviteForInterviewConfirm);
    };

    const candidate = selectedCandidates[0];

    return (
        <ActionConfirmationDialog
            dialogType={DialogEnum.InviteForInterviewConfirm}
            header={<DialogHeader title={UIStrings.InviteForInterview} backgroundColor={theme.palette.primary.main} />}
            body={
                <DialogCardContainer
                    dialogTitle={<InterviewConfirmDialogHeader name={candidate?.name} />}
                    dialogBody={<InterviewConfirmDialogBody name={candidate?.name} />}
                    icon={
                        <img
                            src={`${resourceBaseUrl}/images/ATSMarkInterviewing.png`}
                            width={theme.spacing(29.1875)}
                            height="auto"
                            alt=""
                        />
                    }
                />
            }
            actions={
                <LoadingButton
                    fullWidth
                    size="large"
                    variant="contained"
                    color="primary"
                    loading={isInviteForInterviewLoading}
                    onClick={async () => {
                        await inviteForInterviewApplicantAsync();
                        closeConfirm();
                        showInviteForInterviewNotification();
                    }}
                >
                    {UIStrings.MarkAsInviteForInterview}
                </LoadingButton>
            }
        />
    );
}

export default InviteForInterviewConfirm;
