/* eslint-disable no-nested-ternary */
import { Theme } from '@mui/material';
import { MRT_Column } from 'material-react-table';
import { ApplicantRecruitmentOverviewResponse } from '../../../../../../../Models/CandidateOverview';

const getPinnedCellStyles = ({
    column,
    theme,
}: {
    column?: MRT_Column<ApplicantRecruitmentOverviewResponse>;
    theme: Theme;
}) => {
    const isPinned = column?.getIsPinned();

    // The logic is taken from the material-react-table library
    // https://github.com/KevinVandy/material-react-table/blob/3dbab3235eb53653404fa4131979acb55e8bca5a/packages/material-react-table/src/utils/style.utils.ts#L53
    return {
        '&[data-pinned="true"]': {
            borderRight: column
                ? isPinned === 'left' && column.getIsLastColumn(isPinned)
                    ? `1.5px solid ${theme.palette.border.main}`
                    : undefined
                : undefined,

            borderLeft: column
                ? isPinned === 'right' && column.getIsLastColumn(isPinned)
                    ? `1.5px solid ${theme.palette.border.main}`
                    : undefined
                : undefined,
            '&:before': {
                // MRT adds boxShadow for pinned columns by default
                // We disable it here
                boxShadow: 'none',
            },
        },
    };
};

export default getPinnedCellStyles;
