import { MRT_ColumnDef } from 'material-react-table';
import { ApplicantRecruitmentOverviewResponse } from '../../../../../../../Models/CandidateOverview';
import { TestTypes } from '../../../../../../../Models/Configuration';
import useTestColumns from './useTestColumns';
import useDefaultColumns from './useDefaultColumns';

export type CandidateCol = MRT_ColumnDef<ApplicantRecruitmentOverviewResponse>;
export type CellParams = Parameters<Required<CandidateCol>['Cell']>['0'];

const useColumns = (testTypes: TestTypes[], includesExperienceCriteria: boolean) => {
    const defaultColumns = useDefaultColumns(includesExperienceCriteria);
    const testScoreColumns = useTestColumns(testTypes);

    // be careful of the ordering of these columns
    return [...defaultColumns, ...testScoreColumns];
};

export default useColumns;
